import React, { useState } from "react";
import { Button, Space, Typography, Input, theme } from "antd";
import { SearchOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AvatarWithText } from "../components";

const { Text } = Typography;
const { useToken } = theme;

const Header = () => {
  const { token } = useToken();
  const navigate = useNavigate();

  const handleAvatarClick = () => {
    navigate("/profile");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const firstName = userData?.first_name;
  const lastName = userData?.last_name;
  const userName = userData ? `${firstName} ${lastName}` : "";

  const [isSearchButtonHovered, setIsSearchButtonHovered] = useState(false);

  return (
    <header
      style={{
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 24px",
        borderBottom: token.border,
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Space>
        <Text style={{ fontSize: "20px", fontWeight: "400" }}>
          Welcome Back, {firstName}! 👋
        </Text>
      </Space>
      <Space>
        <Space style={{ marginRight: "20px" }}>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined style={{ color: token.colorGrey }} />}
            suffix={
              <Button
                shape="circle"
                icon={
                  <ArrowRightOutlined
                    onMouseEnter={() => setIsSearchButtonHovered(true)}
                    onMouseLeave={() => setIsSearchButtonHovered(false)}
                    icon={
                      <ArrowRightOutlined
                        style={{
                          color: isSearchButtonHovered
                            ? token.colorPrimary
                            : token.colorGrey,
                        }}
                      />
                    }
                  />
                }
              />
            }
            style={{ borderRadius: "50px", width: "300px" }}
          />
        </Space>
        <AvatarWithText
          text=""
          initials={userName.split(" ")[0][0] + userName.split(" ")[1][0]}
          isBadge={true}
          onClick={handleAvatarClick}
        />
      </Space>
    </header>
  );
};

export default Header;
