import React from "react";
import { Badge, Card, Typography, Flex, theme } from "antd";

const { useToken } = theme;
const { Ribbon } = Badge;
const { Text } = Typography;

function QuizInfoCard({ title, showDifficulty, difficulty, children }) {
  const { token } = useToken();

  const cardContent = (
    <Card
      style={{
        borderRadius: "8px",
        border: token.border,
        borderColor: token.borderColor,
        height: "100%",
      }}
    >
      <Flex vertical>
        {title && (
          <Text strong style={{ marginBottom: 10 }}>
            {title}
          </Text>
        )}
        {children}
      </Flex>
    </Card>
  );

  return showDifficulty ? (
    <Ribbon
      text={difficulty}
      color={
        difficulty === "Easy"
          ? "green"
          : difficulty === "Medium"
          ? "orange"
          : "red"
      }
    >
      {cardContent}
    </Ribbon>
  ) : (
    cardContent
  );
}

export default QuizInfoCard;
