import React, { useState, useRef } from "react";
import { Layout } from "antd";
import { Header, Drawer } from "../components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Content } = Layout;

function MainLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const contentRef = useRef(null);
  const screens = useBreakpoint();

  const isMobileView = !screens.lg;

  return (
    <Layout style={{ minHeight: "100vh", position: "relative" }}>
      <Drawer
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        isMobileView={isMobileView}
      />
      <Layout style={{ width: "100%" }}>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content ref={contentRef}>
          {children({ contentRef, collapsed })}
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
