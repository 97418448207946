import { Flex, theme, Row, Col, Typography } from "antd";
import { BookCard } from "../../dashboard";

const { useToken } = theme;
const { Text } = Typography;

function BooksList() {
  const { token } = useToken();

  return (
    <Flex vertical>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "16px" }}
      >
        <Text strong style={{ fontSize: "16px" }}>
          My Books
        </Text>
        <Text
          style={{
            textDecoration: "underline",
            color: token.colorSuccess,
            cursor: "pointer",
          }}
        >
          View All
        </Text>
      </Flex>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xxl={8}>
          <BookCard
            grade={9}
            bookName="Mathematics Grade 9"
            chaptersCount={12}
            timeTaken={3.5}
            progress={25}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xxl={8}>
          <BookCard
            grade={9}
            bookName="Mathematics Grade 9"
            chaptersCount={12}
            timeTaken={3.5}
            progress={25}
          />
        </Col>
      </Row>
    </Flex>
  );
}

export default BooksList;
