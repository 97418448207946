import React from "react";
import { Card, Typography, Space } from "antd";
import { theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const CreditCard = ({ number, name, expiry, style }) => {
  const { token } = useToken();

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        background: "linear-gradient(45deg, #0a0a0a, #3a4452)",
        color: token.colorWhite,
        borderRadius: 10,
        ...style,
      }}
    >
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Text style={{ color: token.colorWhite, fontSize: 18 }}>
          {number.replace(/(\d{4})/g, "$1 ").trim()}
        </Text>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Text style={{ color: token.colorWhite }}>{name}</Text>
          <Text style={{ color: token.colorWhite }}>{expiry}</Text>
        </Space>
      </Space>
    </Card>
  );
};

export default CreditCard;
