const theme = {
  token: {
    colorPrimary: "#FF6C42",
    colorAccent: "#67a0f4",
    colorBgLayout: "#F5F7FA",
    border: "1px solid #F0F2F2",
    borderColor: "#F0F2F2",
    colorSecondary: "#0143a6",
    colorBgElevated: "#0143a6",

    colorBlack: "#110D1C",
    colorWhite: "#FFFFFF",
    colorGrey: "#566268",
    colorGreen: "#8BC34A",
    colorDarkGreen: "#53752C",
    colorLightGreen: "#F3F9ED",
    colorBlue: "#4C769C",

    colorFooterCard: "#F7F7F8",
    colorFooterBorder: "#A6B0B4",

    borderColorPurple: "#9F7AAC",
    borderColorOrange: "#D15835",
    borderColorGreen: "#8BC34A",
    borderColorLime: "#5D5E37",

    textColorGreen: '#6F9C3B', 
    colorDarkPurple: '#4D2959',
    colorForestGreen: '#3D6A42',
    textColorGrey: '#6B7B82',
    logoBlue: '#145365',
    borderColorGrey: '#E5E5E5',
  },
};

export default theme;
