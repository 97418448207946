import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated, isOnboardingComplete } from "../utils";

const ProtectedRoute = () => {
  if (!isAuthenticated()) {
    return <Navigate to="/signin" replace />;
  }

  if (!isOnboardingComplete()) {
    return <Navigate to="/onboarding" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
