import React, { createContext, useState, useContext } from 'react';

const SelectedChaptersContext = createContext();

export const SelectedChaptersProvider = ({ children }) => {
  const [selectedChapters, setSelectedChapters] = useState([]);

  return (
    <SelectedChaptersContext.Provider value={{ selectedChapters, setSelectedChapters }}>
      {children}
    </SelectedChaptersContext.Provider>
  );
};

export const useSelectedChapters = () => useContext(SelectedChaptersContext);
