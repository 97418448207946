const ReportsDrawerIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_344_375)">
        <path
          d="M12.6379 5.1375L13.7179 6.2175L10.0579 9.8775L7.59035 7.41C7.29785 7.1175 6.82535 7.1175 6.53285 7.41L2.03285 11.9175C1.74035 12.21 1.74035 12.6825 2.03285 12.975C2.32535 13.2675 2.79785 13.2675 3.09035 12.975L7.05785 9L9.52535 11.4675C9.81785 11.76 10.2904 11.76 10.5829 11.4675L14.7754 7.2825L15.8554 8.3625C16.0879 8.595 16.4929 8.43 16.4929 8.1V4.875C16.5004 4.665 16.3354 4.5 16.1254 4.5H12.9079C12.5704 4.5 12.4054 4.905 12.6379 5.1375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_344_375">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportsDrawerIcon;
