const LangPracticeDrawerIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_344_396)">
        <path
          d="M16.5 3.885L7.9425 12.45L4.7625 9.27L5.82 8.2125L7.9425 10.335L15.4425 2.835L16.5 3.885ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C10.1775 3 11.28 3.345 12.21 3.9375L13.2975 2.85C12.075 2.0025 10.5975 1.5 9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C10.2975 16.5 11.52 16.17 12.585 15.585L11.46 14.46C10.71 14.805 9.8775 15 9 15ZM14.25 11.25H12V12.75H14.25V15H15.75V12.75H18V11.25H15.75V9H14.25V11.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_344_396">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LangPracticeDrawerIcon;
