import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { PDFCard } from "../pages/book";

const PDFModal = ({ visible, onClose }) => {
  const [viewerWidth, setViewerWidth] = useState(300);
  const [viewerHeight, setViewerHeight] = useState(400);

  const updateViewerDimensions = () => {
    const screenWidth = window.innerWidth;
    let widthRatio, heightRatio;

    if (screenWidth >= 1800) {
      widthRatio = 0.35;
      heightRatio = 0.49;
    } else if (screenWidth >= 1440) {
      widthRatio = 0.42;
      heightRatio = 0.55;
    } else if (screenWidth >= 700) {
      widthRatio = 0.45;
      heightRatio = 0.68;
    } else {
      widthRatio = 0.5;
      heightRatio = 0.76;
    }

    setViewerWidth(screenWidth * widthRatio);
    setViewerHeight(screenWidth * heightRatio);
  };

  useEffect(() => {
    updateViewerDimensions();

    const handleResize = () => updateViewerDimensions();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      title="PDF Viewer"
      open={visible}
      footer={null}
      onCancel={onClose}
      width={viewerWidth + 180}
      styles={{
        content: {
          background: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
        header: { background: "white" },
      }}
    >
      <PDFCard viewerWidth={viewerWidth} viewerHeight={viewerHeight} />
    </Modal>
  );
};

export default PDFModal;
