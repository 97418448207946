import React from "react";
import { Flex, Button, Form, Input, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import { AppLogo } from "../../../components";

const { Title, Text } = Typography;

export default function ForgotEmail() {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values:", values);
    // Handle forgot email logic here
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        width: "100vw",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Flex
        vertical
        align="center"
        style={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <AppLogo height="70px" />
        </Flex>

        <Flex
          vertical
          align="center"
          style={{
            backgroundColor: "#fff",
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
            padding: "40px",
            boxShadow: "0 8px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Title level={1} style={{ margin: 0 }}>
            Forgot Email
          </Title>
          <Text style={{ marginTop: "10px", textAlign: "center" }}>
            Enter your phone number to recover your email
          </Text>
          <Divider />

          <Form
            form={form}
            name="forgot_email"
            onFinish={onFinish}
            layout="vertical"
            style={{ width: "100%", maxWidth: "300px" }}
          >
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please input your phone number!" },
                {
                  pattern: /^\d{10}$/,
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", backgroundColor: "#5952d7" }}
              >
                Recover Email
              </Button>
            </Form.Item>
          </Form>

          <Flex justify="center" style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "14px", color: "#5952d7" }}>
              Back to{" "}
              <Link to="/signin">
                <Text style={{ color: "#5952d7" }} strong>
                  Sign In
                </Text>
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
