import React from "react";
import styled from "styled-components";
import BlueShadow from "../../components/blue-shadow";
import LandingHeader from "../../components/landing-header";
import LandingContent from "../../components/landing-content";
import LandingCardSection from "../../components/landing-card-section";
import LandingFooter from "../../components/landing-footer";
import CoveredSection from "../../components/covered-section/covered-section";
import CounterSection from "../../components/counter-section";
import BenefitsSection from "../../components/benefits-section";
import AiWorksSection from "../../components/ai-works-section";
import RecommendedSection from "../../components/recommended-section";
import TestimonialSection from "../../components/testimonial-section";

const PageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const LandingPage = () => {
  return (
    <PageContainer>
      <BlueShadow left="20%" bottom="35%" />
      <BlueShadow left="5%" bottom="1%" />
      <LandingHeader currentPage="Home" />
      <LandingContent />
      <CoveredSection />
      <CounterSection />
      <AiWorksSection />
      <BenefitsSection />
      <TestimonialSection />
      {/* <RecommendedSection /> */}
      <LandingCardSection />
      <LandingFooter />
    </PageContainer>
  );
};

export default LandingPage;
