import React from "react";
import { Card, Typography, Progress, theme, Flex } from "antd";
import { AppDropdown, StatTextRow } from "../../components";

const { Text } = Typography;
const { useToken } = theme;

const ProgressCard = ({ subjectOptions }) => {
  const { token } = useToken();

  return (
    <Card
      style={{
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        padding: "24px",
        marginBottom: "16px",
        borderColor: token.colorBgLayout,
      }}
      bodyStyle={{ padding: "0px" }}
    >
      {/* Header Row */}
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "24px" }}
      >
        <Text strong style={{ fontSize: "16px" }}>
          Progress
        </Text>
        <AppDropdown defaultValue="all" options={subjectOptions} />
      </Flex>

      {/* Progress Circle */}
      <Flex justify="center" style={{ marginBottom: "24px" }}>
        <Progress
          type="circle"
          size={150}
          strokeWidth={8}
          percent={75}
          strokeColor={token.colorPrimary}
          trailColor={token.colorPrimaryBg}
        />
      </Flex>

      {/* Bottom Stats Row */}
      <StatTextRow
        value="5"
        description="Increase since last week"
        isIncrease={true}
      />
    </Card>
  );
};

export default ProgressCard;
