import React from "react";
import { Flex, Button, Layout, Typography, theme } from "antd";
import { ASSETS } from "../../../constants";
import { ProgressCard, ScoreCard } from "../../../components";

const { Sider } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

function DashboardSider() {
  const { token } = useToken();

  const subjectOptions = [
    { value: "all", label: "Subjects" },
    { value: "math", label: "Mathematics" },
    { value: "physics", label: "Physics" },
    { value: "chemistry", label: "Chemistry" },
  ];

  const renderGoPremiumCard = () => {
    return (
      <Flex
        style={{
          width: "100%",
          backgroundColor: token.colorWhite,
          borderRadius: "16px",
          padding: "24px",
          position: "relative",
          overflow: "hidden",
          marginBottom: "16px",
        }}
      >
        {/* Background Image */}
        <div
          style={{
            position: "absolute",
            bottom: "-40%",
            right: "-40%",
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <img
            src={ASSETS.dashboardPremiumCardBackground}
            alt="Background"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>

        {/* Left Side Content */}
        <Flex
          vertical
          style={{
            width: "50%",
            zIndex: 2,
          }}
        >
          <img src={ASSETS.dashboardPremiumCardHeader} alt="Premium" />
          <Title level={4} style={{ margin: 0, paddingTop: "24px" }}>
            Go Premium
          </Title>
          <Text style={{ marginTop: "8px" }}>
            Get more with Premium: access all content, study materials, and
            expert support to reach your goals!
          </Text>
          <Button
            type="primary"
            style={{
              marginTop: "16px",
              borderRadius: "24px",
              backgroundColor: token.colorPrimary,
              color: token.colorWhite,
              width: "fit-content",
            }}
          >
            Get Access
          </Button>
        </Flex>

        {/* Right Side Animation */}
        <Flex
          vertical
          style={{
            width: "70%",
            zIndex: 2,
          }}
        >
          <img
            src={ASSETS.dashboardPremiumCardAnimation}
            alt="Premium Animation"
            style={{
              paddingTop: "10%",
              width: "100",
              height: "auto",
            }}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <Sider
      style={{
        backgroundColor: token.colorBgLayout,
        marginLeft: "16px",
      }}
      width={"30%"}
    >
      {renderGoPremiumCard()}
      <ProgressCard subjectOptions={subjectOptions} />
      <ScoreCard
        title="Highest Score"
        subject="Physics"
        score={80.9}
        date="2025-11-26"
      />
      <ScoreCard
        title="Lowest Score"
        subject="Mathematics"
        score={20.43}
        date="2025-11-25"
      />
    </Sider>
  );
}

export default DashboardSider;
