import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Layout,
  Typography,
  Avatar,
  Pagination,
  Button,
  theme,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  QuizInfoCardsSection,
  QuizOptionCard,
  QuizInfoStickyBar,
} from "../../components";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { Content } = Layout;
const { Paragraph } = Typography;

function QuizDisplay({
  quizType,
  difficulty,
  numQuestions,
  allQuestions,
  quizData,
  onQuizEnd,
}) {
  const { token } = useToken();
  const [quizTime, setQuizTime] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(
    Array(quizData.length).fill(null)
  );
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [showStickyBar, setShowStickyBar] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [currentSkippedQuestions, setCurrentSkippedQuestions] = useState([]); // Tracks current skips for quiz flow
  const [skippedQuestionsForResults, setSkippedQuestionsForResults] = useState(
    []
  ); // Tracks total skipped for results
  const contentRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPaused && !isSubmitted) {
      const id = setInterval(() => {
        setQuizTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimerId(id);
      return () => clearInterval(id);
    } else {
      clearInterval(timerId);
    }
  }, [isPaused, isSubmitted]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePageChange = (page) => {
    setCurrentQuestionIndex(page - 1);
  };

  const handleSkipQuestion = (questionIndex) => {
    if (!isSubmitted && !currentSkippedQuestions.includes(questionIndex)) {
      setCurrentSkippedQuestions((prev) => [...prev, questionIndex]);
      setSkippedQuestionsForResults((prev) => [...prev, questionIndex]);

      setAnsweredQuestions((prev) => {
        const newAnswered = [...prev];
        newAnswered[questionIndex] = "skipped";
        return newAnswered;
      });

      // Automatically move to the next question
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex < quizData.length - 1
          ? prevIndex + 1
          : currentSkippedQuestions[0]
      );
    }
  };

  const handleAnswerSelect = (isCorrect, questionIndex, optionIndex) => {
    if (!isSubmitted) {
      setAnsweredQuestions((prev) => {
        const newAnswered = [...prev];

        // Check if this is a newly answered question to increment progress
        const isNewAnswer =
          newAnswered[questionIndex] === null ||
          currentSkippedQuestions.includes(questionIndex);
        newAnswered[questionIndex] = optionIndex;

        if (isNewAnswer) {
          setQuestionsAnswered((prevCount) => prevCount + 1);
        }

        // Remove from currentSkippedQuestions if it was previously skipped
        setCurrentSkippedQuestions((prevSkipped) =>
          prevSkipped.filter((idx) => idx !== questionIndex)
        );

        return newAnswered;
      });

      // Automatically move to the next question
      setCurrentQuestionIndex((prevIndex) => {
        if (prevIndex < quizData.length - 1) {
          return prevIndex + 1;
        } else if (currentSkippedQuestions.length > 0) {
          return currentSkippedQuestions[0];
        }
        return prevIndex;
      });
    }
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSkippedQuestions.length > 0) {
      const nextSkippedQuestion = currentSkippedQuestions.shift();
      setCurrentQuestionIndex(nextSkippedQuestion);
      setCurrentSkippedQuestions([...currentSkippedQuestions]);
    }
  };

  const handlePauseResume = () => {
    setIsPaused((prev) => !prev);
  };

  const handleRestart = () => {
    setQuestionsAnswered(0);
    setQuizTime(0);
    setCurrentQuestionIndex(0);
    setAnsweredQuestions(Array(quizData.length).fill(null));
    setIsPaused(false);
    setIsSubmitted(false);
    setCurrentSkippedQuestions([]);
  };

  const handleStop = () => {
    onQuizEnd();
  };

  const handleSubmitQuiz = () => {
    const score = calculateScore();
    navigate("/result", {
      state: {
        quizData,
        userAnswers: answeredQuestions,
        timeElapsed: formatTime(quizTime),
        score,
        skippedQuestions: skippedQuestionsForResults,
      },
    });
  };

  const calculateScore = () => {
    let correct = 0;
    let totalAnswered = 0;

    answeredQuestions.forEach((answer, index) => {
      if (answer !== null && !currentSkippedQuestions.includes(index)) {
        totalAnswered++;
        if (quizData[index].options[answer].isCorrect) {
          correct++;
        }
      }
    });

    return totalAnswered === 0
      ? 0
      : Math.round((correct / totalAnswered) * 100);
  };

  const allQuestionsAnswered = answeredQuestions.every((q) => q !== null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = contentRef.current?.scrollTop || 0;
      setScrollY(scrollPosition);
      setShowStickyBar(scrollPosition > 200);
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
      return () => contentElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
        backgroundColor: token.colorWhite,
      }}
    >
      <Content
        ref={contentRef}
        style={{
          padding: "0px 12px 12px 12px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <AnimatePresence>
          {showStickyBar && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }}
            >
              <QuizInfoStickyBar
                questionsAnswered={questionsAnswered}
                totalQuestions={quizData.length}
                timeLeft={formatTime(quizTime)}
                onPauseResume={handlePauseResume}
                onRestart={handleRestart}
                onStop={handleStop}
                isPaused={isPaused}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          animate={{
            opacity: showStickyBar ? 0 : 1,
            height: showStickyBar ? 0 : "auto",
            marginBottom: showStickyBar ? 0 : 24,
          }}
          transition={{ duration: 0.3 }}
        >
          <QuizInfoCardsSection
            difficulty={difficulty}
            timeLeft={formatTime(quizTime)}
            questionsAnswered={questionsAnswered}
            totalQuestions={quizData.length}
            onPauseResume={handlePauseResume}
            onRestart={handleRestart}
            onStop={handleStop}
            isPaused={isPaused}
          />
        </motion.div>

        <Flex vertical style={{ marginTop: 10 }}>
          {/* Render questions depending on allQuestions prop */}
          {allQuestions ? (
            quizData.map((quiz, idx) => (
              <Flex
                key={idx}
                style={{
                  marginBottom: 24,
                  width: "100%",
                }}
                align="flex-start"
                vertical
              >
                <Flex
                  style={{
                    marginBottom: 12,
                    width: "100%",
                  }}
                  align="center"
                >
                  <Avatar
                    style={{
                      backgroundColor: token.colorPrimaryBg,
                      color: token.colorPrimary,
                      marginRight: 12,
                      flexShrink: 0,
                    }}
                  >
                    {idx + 1}
                  </Avatar>
                  <Paragraph style={{ margin: 0, flex: 1, fontWeight: 500 }}>
                    {quiz.question}
                    {quiz.isRedemption && (
                      <Tooltip title="This is a question that you previously answered incorrectly.">
                        <span style={{ marginLeft: 8 }}>
                          🔥 <InfoCircleOutlined style={{ marginLeft: 4 }} />
                        </span>
                      </Tooltip>
                    )}
                    {quiz.isPreviouslySkipped && (
                      <Tooltip title="This is a question that you previously skipped.">
                        <span style={{ marginLeft: 8 }}>
                          ⏪ <InfoCircleOutlined style={{ marginLeft: 4 }} />
                        </span>
                      </Tooltip>
                    )}
                  </Paragraph>
                  {/* <Button
                  type="link"
                  onClick={() => handleSkipQuestion(idx)}
                  style={{ marginLeft: "auto" }}
                >
                  Skip
                </Button> */}
                </Flex>

                <Flex vertical style={{ width: "100%" }}>
                  {quiz.options.map((option, optIdx) => (
                    <QuizOptionCard
                      key={optIdx}
                      optionNumber={String.fromCharCode(65 + optIdx)}
                      text={option.text}
                      isCorrect={option.isCorrect}
                      onClick={() =>
                        handleAnswerSelect(option.isCorrect, idx, optIdx)
                      }
                      highlighted={answeredQuestions[idx] === optIdx} // Highlight the selected option
                      showCorrect={isSubmitted} // Show correctness after submission
                      isWrong={
                        isSubmitted &&
                        answeredQuestions[idx] === optIdx &&
                        !option.isCorrect
                      } // Highlight wrong answers in red
                    />
                  ))}
                </Flex>
              </Flex>
            ))
          ) : (
            <>
              {/* If not allQuestions, render the current question with pagination */}
              <Flex
                key={currentQuestionIndex}
                style={{
                  margin: 12,
                }}
                align="center"
              >
                <Avatar
                  style={{
                    backgroundColor: token.colorPrimaryBg,
                    color: token.colorPrimary,
                    marginRight: 12,
                    flexShrink: 0,
                  }}
                >
                  {currentQuestionIndex + 1}
                </Avatar>
                <Paragraph style={{ margin: 0, fontWeight: 500 }}>
                  {quizData[currentQuestionIndex].question}
                  {quizData[currentQuestionIndex].isRedemption && (
                    <Tooltip title="This is a question that you previously answered incorrectly.">
                      <span style={{ marginLeft: 8 }}>
                        🔥 <InfoCircleOutlined style={{ marginLeft: 4 }} />
                      </span>
                    </Tooltip>
                  )}
                  {quizData[currentQuestionIndex].isPreviouslySkipped && (
                    <Tooltip title="This is a question that you previously skipped.">
                      <span style={{ marginLeft: 8 }}>
                        ⏪ <InfoCircleOutlined style={{ marginLeft: 4 }} />
                      </span>
                    </Tooltip>
                  )}
                </Paragraph>
                <Button
                  type="link"
                  onClick={() => handleSkipQuestion(currentQuestionIndex)}
                  style={{ marginLeft: "auto" }}
                >
                  Skip
                </Button>
              </Flex>

              <Flex vertical style={{ marginTop: 4 }}>
                {quizData[currentQuestionIndex].options.map((option, idx) => (
                  <QuizOptionCard
                    key={idx}
                    optionNumber={String.fromCharCode(65 + idx)}
                    text={option.text}
                    isCorrect={option.isCorrect}
                    onClick={() =>
                      handleAnswerSelect(
                        option.isCorrect,
                        currentQuestionIndex,
                        idx
                      )
                    }
                    highlighted={
                      answeredQuestions[currentQuestionIndex] === idx
                    }
                    showCorrect={isSubmitted}
                    isWrong={
                      isSubmitted &&
                      answeredQuestions[currentQuestionIndex] === idx &&
                      !option.isCorrect
                    }
                  />
                ))}
              </Flex>

              <Flex justify="space-between" style={{ marginTop: 12 }}>
                <Button
                  type="primary"
                  onClick={handleSubmitQuiz}
                  disabled={!allQuestionsAnswered}
                >
                  Submit Quiz
                </Button>
                <Pagination
                  align="end"
                  current={currentQuestionIndex + 1}
                  total={numQuestions}
                  pageSize={1}
                  onChange={handlePageChange}
                />
              </Flex>
            </>
          )}
        </Flex>

        {allQuestions && (
          <Button
            type="primary"
            style={{ maxWidth: "200px", margin: "auto" }}
            onClick={handleSubmitQuiz}
            disabled={!allQuestionsAnswered}
          >
            Submit Quiz
          </Button>
        )}
      </Content>
    </Layout>
  );
}

export default QuizDisplay;
