import React from "react";
import { Typography, Button, theme } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { useToken } = theme;

const CardContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 24px;
    margin-top: 24px;
  }
`;

const StyledCard = styled.div`
  background-color: ${(props) => props.theme.token.colorPrimary};
  border-radius: 16px;
  padding: 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
  max-width: 100%;
`;

const BackgroundGrid = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @media (max-width: 768px) {
    height: 80%;
    width: auto;
  }
`;

const EllipseLeft = styled.img`
  position: absolute;
  bottom: 0;
  left: 40px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 180px;
    height: auto;
    bottom: auto;
    top: 0;
    left: -30%;
    transform: rotate(180deg);
  }
`;

const EllipseRight = styled.img`
  position: absolute;
  top: 50%;
  right: -60px;
  transform: translateY(-50%) rotate(270deg);
  z-index: 1;

  @media (max-width: 768px) {
    width: 180px;
    height: auto;
    top: auto;
    bottom: 0;
    right: -30%;
    transform: rotate(0deg);
  }
`;

const LandingCardSection = () => {
  const { token } = useToken();

  return (
    <CardContainer>
      <StyledCard theme={{ token }}>
        <BackgroundGrid src="images/squares-grid.svg" alt="Grid Pattern" />
        <EllipseLeft src="images/ellipse-graphic.svg" alt="Ellipse Left" />
        <EllipseRight src="images/ellipse-graphic.svg" alt="Ellipse Right" />

        <TextContainer>
          <Text
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: token.colorWhite,
              marginBottom: "12px",
              maxWidth: "280px",
              lineHeight: "24px",
            }}
          >
            The first step to unlocking your potential is right here.
          </Text>
          <Text
            style={{
              fontSize: "36px",
              fontWeight: 500,
              color: token.colorWhite,
              marginBottom: "19px",
              whiteSpace: "pre-line",
              lineHeight: "44px",
              maxWidth: "320px",
            }}
          >
            {"Start your journey today and see the difference!"}
          </Text>
          <Link to="/signup">
            <Button
              style={{
                width: "132px",
                height: "48px",
                padding: "12px 16px",
                fontSize: "12px",
                fontWeight: 400,
                textDecoration: "none",
                backgroundColor: token.borderColor,
                color: token.colorPrimary,
                border: `1px solid ${token.colorPrimary}`,
              }}
            >
              Sign up for free
            </Button>
          </Link>
        </TextContainer>
      </StyledCard>
    </CardContainer>
  );
};

export default LandingCardSection;
