import React from "react";
import { Layout, Menu, theme, Checkbox } from "antd";
import { useSelectedChapters } from "../../../contexts";
import { BookFilled } from "@ant-design/icons";
import styled from "styled-components";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { useToken } = theme;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FF6C42 !important;
    border-color: '#FF6C42' !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #FF6C42 !important;
    border-color: '#FF6C42' !important;
  }

  .ant-checkbox-inner {
    border-color: #FF6C42 !important;  // Keep the default border color when unchecked
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #FF6C42 !important;  // Keep the default border color on hover when unchecked
  }
`;

const CustomMenuItem = styled(Menu.Item)`
  background-color: transparent !important;
  background-color::hover: transparent !important;
  color: black !important;
  color::hover: black !important;
  border-bottom: 1px solid #F5F7FA; /* Default border for all items */
  border-radius: 0;
  padding: 0;

  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

function ChaptersMenu({ bookData }) {
  const { token } = useToken();
  const { selectedChapters, setSelectedChapters } = useSelectedChapters();

  const handleChapterSelect = (e, chapterTitle) => {
    e.stopPropagation();
    if (selectedChapters.includes(chapterTitle)) {
      setSelectedChapters(
        selectedChapters.filter((title) => title !== chapterTitle)
      );
    } else {
      setSelectedChapters([...selectedChapters, chapterTitle]);
    }
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    if (selectedChapters.length === bookData.chapters.length) {
      setSelectedChapters([]);
    } else {
      setSelectedChapters(bookData.chapters.map((chapter) => chapter.title));
    }
  };

  const generateMenuItems = () => {
    const menuItems = [];

    // "All" option
    menuItems.push(
      <CustomMenuItem
        key="all"
        icon={
          <CustomCheckbox
            checked={selectedChapters.length === bookData.chapters.length}
            onClick={handleSelectAll}
            style={{ marginRight: "6px" }}
          />
        }
      >
        <img
          src="/images/book_icon.svg"
          alt="Book Icon"
          style={{
            marginRight: "8px",
            height: "16px",
            width: "auto",
            transform: "translateY(3px)", // Moves the icon 3px downward
          }}
        />
        All
      </CustomMenuItem>
    );

    // Chapters
    menuItems.push(
      ...bookData.chapters.map((chapter) => (
        <CustomMenuItem key={`chapter-${chapter.id}`}>
          <CustomCheckbox
            checked={selectedChapters.includes(chapter.title)}
            onClick={(e) => handleChapterSelect(e, chapter.title)}
            style={{ marginRight: "16px" }}
          />
          <img
            src="/images/book_icon.svg"
            alt="Book Icon"
            style={{
              marginRight: "8px",
              height: "16px",
              width: "auto",
              transform: "translateY(3px)", // Moves the icon 3px downward
            }}
          />
          {`${chapter.title}`}
        </CustomMenuItem>
      ))
    );

    return menuItems;
  };

  return (
    <Menu
      mode="inline"
      style={{
        border: "none",
      }}
    >
      {generateMenuItems()}
    </Menu>
  );
}

export default ChaptersMenu;
