import React, { useState } from "react";
import { Typography, theme, Rate } from "antd";
import styled from "styled-components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ASSETS } from "../constants";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 40px 24px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
    
    h1 {
      max-width: 300px;
      word-wrap: break-word;
    }
  }
`;

const DescriptionText = styled(Text)`
  max-width: 600px;
  text-align: center;
  white-space: pre-line;

  @media (max-width: 768px) {
    max-width: 300px;
    white-space: normal;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 44px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 16px;
    margin: 0 -24px 32px 0;
    padding: 0 24px 0 48px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.cards-container {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      gap: 16px;
      margin: 0 -24px 32px 0;
      padding: 0 24px 0 48px;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const TestimonialCard = styled.div`
  background-color: ${(props) => props.theme.token.colorFooterCard};
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  width: ${(props) => (props.isExpanded ? "calc(100% + 10px)" : "100%")};
  height: ${(props) => (props.isExpanded ? "320px" : "300px")};
  transform: ${(props) => (props.isExpanded ? "translateX(-5px)" : "none")};

  @media (max-width: 768px) {
    min-width: 85%;
    scroll-snap-align: start;
    &:last-child {
      margin-right: 24px;
    }
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BottomSection = styled.div`
  margin-top: auto;
`;

const QuoteSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AvatarSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const QuoteGraphic = styled.img`
  width: 52px;
  height: 52px;
`;

const PlatformLabel = styled(Text)`
  color: ${(props) => props.theme.token.colorGrey};
  display: block;
  margin-bottom: 8px;
`;

const TestimonialText = styled(Text)`
  color: ${(props) => props.theme.token.colorBlack};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isExpanded ? 'none' : '6')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
`;

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
`;

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transform: scale(0.8);
`;

const PaginationLine = styled.div`
  width: 50px;
  height: 2px;
  background-color: ${(props) => props.theme.token.colorGrey}20;
  position: relative;
`;

const ProgressLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${(props) => props.theme.token.colorPrimary};
  width: ${(props) => (props.current / props.total) * 100}%;
  transition: width 0.3s ease;
`;

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const HatGraphic = styled.img`
  position: absolute;
  width: 72px;
  height: auto;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    width: 50px;
    left: -5px;
  }
`;

const ShineGraphic = styled.img`
  position: absolute;
  width: 33px;
  height: 33px;
  right: 10%;
  top: 0;
  transform: rotate(180deg);

  @media (max-width: 768px) {
    right: calc(50% - 160px);
  }
`;

const TestimonialSection = () => {
  const { token } = useToken();
  const [selectedCard, setSelectedCard] = useState(0);

  const testimonials = [
    {
      name: "Aditia Roy",
      designation: "Student",
      text: "GradeMakers has transformed how I study! I can now ask my textbooks questions and get clear answers instantly. Preparing for exams feels like a fun challenge instead of a chore. The practice tests are like a game—every time I see my scores improve, I get more motivated to keep going!",
    },
    {
      name: "Alisha P.",
      designation: "Student",
      text: "Practicing a foreign language with the AI bots feels like talking to a friend! It’s so realistic and fun. I’ve improved my French accent so much in just a few weeks.",
    },
    {
      name: "Anonymous",
      designation: "Parent",
      text: "GradeMakers is great learning & practice tool for my children. Its fun to chat with the textbooks.",
    },
    {
      name: "Priya S.",
      designation: "Parent",
      text: "GradeMakers gives us detailed reports that help us understand our child’s strengths and weaknesses. It’s not just about scores; it’s about meaningful insights. I wish I had this tool when I was a student!",
    },
    {
      name: "Neha L.",
      designation: "Parent",
      text: "As working parents, it’s hard to guide our child every day. GradeMakers fills that gap wonderfully. The AI explanations are simple yet effective, and the instant evaluations save so much time.",
    },
    {
      name: "Anita R.",
      designation: "Teacher",
      text: "The AI is surprisingly accurate and effective in explaining complex concepts. Students come to class better prepared, and I spend less time on repetitive questions. It’s a great way to enhance blended learning.",
    },
    {
      name: "Rita M.",
      designation: "Principal",
      text: "The technology behind GradeMakers is revolutionary. It addresses the unique needs of every student, ensuring no one is left behind. It’s the perfect blend of innovation and education.",
    },
  ].map((testimonial, index) => ({
    ...testimonial,
    image: `https://doodleipsum.com/100x100/avatar?shape=circle&bg=lightgray&n=${
      index + 1
    }`,
  }));

  const totalCards = testimonials.length;

  const getDisplayIndices = (selected) => {
    if (selected < 3) {
      return [0, 1, 2, 3];
    } else if (selected >= testimonials.length - 1) {
      return [
        testimonials.length - 4,
        testimonials.length - 3,
        testimonials.length - 2,
        testimonials.length - 1,
      ];
    } else {
      return [selected - 3, selected - 2, selected - 1, selected];
    }
  };

  const displayIndices = getDisplayIndices(selectedCard);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    if (window.innerWidth <= 768) {
      const card = document.querySelector(`[data-index="${index}"]`);
      const container = document.querySelector('.cards-container');
      if (card && container) {
        const scrollLeft = card.offsetLeft - 48;
        container.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    }
  };

  const handlePrevCard = () => {
    if (selectedCard > 0) {
      setSelectedCard(selectedCard - 1);
      if (window.innerWidth <= 768) {
        const card = document.querySelector(`[data-index="${selectedCard - 1}"]`);
        const container = document.querySelector('.cards-container');
        if (card && container) {
          const scrollLeft = card.offsetLeft - 48;
          container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  const handleNextCard = () => {
    if (selectedCard < totalCards - 1) {
      setSelectedCard(selectedCard + 1);
      if (window.innerWidth <= 768) {
        const card = document.querySelector(`[data-index="${selectedCard + 1}"]`);
        const container = document.querySelector('.cards-container');
        if (card && container) {
          const scrollLeft = card.offsetLeft - 48;
          container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  return (
    <SectionContainer>
      <HeaderWrapper>
        <HatGraphic src={ASSETS.testimonialsHatGraphic} alt="Hat graphic" />
        <ShineGraphic src={ASSETS.shineThreeLinesGraphic} alt="Shine graphic" />
        <HeaderSection>
          <Title
            style={{
              fontSize: "48px",
              fontWeight: 600,
              marginBottom: "16px",
              "@media (max-width: 768px)": {
                fontSize: "36px",
              },
            }}
          >
            Testimonials and Review
          </Title>
          <DescriptionText
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: token.textColorGrey,
            }}
          >
            Here is what Students, Parents & Teachers are saying about GradeMakers!
          </DescriptionText>
        </HeaderSection>
      </HeaderWrapper>

      <CardsContainer className="cards-container">
        {window.innerWidth <= 768
          ? testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                data-index={index}
                isExpanded={selectedCard === index}
                onClick={() => handleCardClick(index)}
                theme={{ token }}
              >
                <CardContent>
                  <PlatformLabel theme={{ token }}>BEST PLATFORM</PlatformLabel>
                  <TestimonialText 
                    theme={{ token }} 
                    isExpanded={selectedCard === index}
                  >
                    {testimonial.text}
                  </TestimonialText>

                  {selectedCard === index &&
                    testimonial.rating && (
                      <div style={{ marginBottom: "16px" }}>
                        <Rate
                          disabled
                          defaultValue={testimonial.rating}
                          style={{
                            color: token.colorPrimary,
                            fontSize: "16px",
                          }}
                        />
                        <Text style={{ marginLeft: "8px" }}>
                          {testimonial.rating}
                        </Text>
                      </div>
                    )}

                  <BottomSection>
                    {selectedCard !== index && (
                      <QuoteSection>
                        <QuoteGraphic src="images/quote-graphic.svg" alt="Quote" />
                      </QuoteSection>
                    )}

                    <ProfileSection>
                      <AvatarSection>
                        <Avatar
                          src={testimonial.image}
                          alt={testimonial.name}
                        />
                        <div>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              display: "block",
                            }}
                          >
                            {testimonial.name}
                          </Text>
                          <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                            {testimonial.designation}
                          </Text>
                        </div>
                      </AvatarSection>

                      {selectedCard === index && (
                        <QuoteGraphic src="images/quote-graphic.svg" alt="Quote" />
                      )}
                    </ProfileSection>
                  </BottomSection>
                </CardContent>
              </TestimonialCard>
            ))
          : displayIndices.map((displayIndex) => (
              <TestimonialCard
                key={displayIndex}
                data-index={displayIndex}
                isExpanded={selectedCard === displayIndex}
                onClick={() => handleCardClick(displayIndex)}
                theme={{ token }}
              >
                <CardContent>
                  <PlatformLabel theme={{ token }}>BEST PLATFORM</PlatformLabel>
                  <TestimonialText 
                    theme={{ token }} 
                    isExpanded={selectedCard === displayIndex}
                  >
                    {testimonials[displayIndex].text}
                  </TestimonialText>

                  {selectedCard === displayIndex &&
                    testimonials[displayIndex].rating && (
                      <div style={{ marginBottom: "16px" }}>
                        <Rate
                          disabled
                          defaultValue={testimonials[displayIndex].rating}
                          style={{
                            color: token.colorPrimary,
                            fontSize: "16px",
                          }}
                        />
                        <Text style={{ marginLeft: "8px" }}>
                          {testimonials[displayIndex].rating}
                        </Text>
                      </div>
                    )}

                  <BottomSection>
                    {selectedCard !== displayIndex && (
                      <QuoteSection>
                        <QuoteGraphic src="images/quote-graphic.svg" alt="Quote" />
                      </QuoteSection>
                    )}

                    <ProfileSection>
                      <AvatarSection>
                        <Avatar
                          src={testimonials[displayIndex].image}
                          alt={testimonials[displayIndex].name}
                        />
                        <div>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              display: "block",
                            }}
                          >
                            {testimonials[displayIndex].name}
                          </Text>
                          <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                            {testimonials[displayIndex].designation}
                          </Text>
                        </div>
                      </AvatarSection>

                      {selectedCard === displayIndex && (
                        <QuoteGraphic src="images/quote-graphic.svg" alt="Quote" />
                      )}
                    </ProfileSection>
                  </BottomSection>
                </CardContent>
              </TestimonialCard>
            ))}
      </CardsContainer>

      <PaginationContainer>
        <LeftOutlined
          onClick={handlePrevCard}
          style={{
            cursor: selectedCard > 0 ? "pointer" : "not-allowed",
            fontSize: "12px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <Text style={{ fontSize: "12px" }}>{`${
            selectedCard + 1
          }/${totalCards}`}</Text>
          <PaginationLine theme={{ token }}>
            <ProgressLine
              theme={{ token }}
              current={selectedCard + 1}
              total={totalCards}
            />
          </PaginationLine>
        </div>
        <RightOutlined
          onClick={handleNextCard}
          style={{
            cursor: selectedCard < totalCards - 1 ? "pointer" : "not-allowed",
            fontSize: "12px",
          }}
        />
      </PaginationContainer>
    </SectionContainer>
  );
};

export default TestimonialSection;
