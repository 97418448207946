import React, { useEffect } from "react";
import { Layout, Typography } from "antd";
import { motion } from "framer-motion";
import { ASSETS } from "../../constants";
const { Content } = Layout;

export default function WelcomeScreen({ onComplete }) {
  useEffect(() => {
    console.log("Setting timeout...");
    // Trigger the `onComplete` callback after 3 seconds
    const timer = setTimeout(() => {
      console.log("Timeout completed");
      onComplete();
    }, 4000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onComplete]);

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#FF6C42",
        overflow: "hidden", // Prevent scrolling
        animation: "slideIn 1.5s ease",
      }}
    >
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          color: "#fff",
        }}
      >
        {/* Slide-in Animation for the Whole Screen */}
        <motion.div
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: "0%", opacity: 1 }}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Animated Text */}
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.5,
              duration: 1,
              type: "spring",
              stiffness: 100,
            }}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Typography.Title
              level={1}
              style={{
                color: "#fff",
                fontSize: "48px",
                fontWeight: "bold",
                marginBottom: "12px",
              }}
            >
              Welcome to GradeMakers
            </Typography.Title>
            <Typography.Text
              style={{
                color: "#fff",
                fontSize: "18px",
                fontWeight: "400",
                display: "block",
                marginBottom: "24px",
              }}
            >
              Just a few steps to go.
            </Typography.Text>
          </motion.div>
        </motion.div>

        {/* Large Waving Hand GIF */}
        <img
          src={ASSETS.welcomeWave}
          alt="Waving Hand"
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            maxWidth: "400px",
          }}
        />
      </Content>
    </Layout>
  );
}
