import React, { useState } from "react";
import { Flex } from "antd";
import {
  RecentResultCard,
  HoursActivityChartCard,
  OverallProgressChartCard,
} from "../../../components";

// This component will have the reports components that will be displayed on the dashboard
const DashboardReports = () => {
  const [selectedTimeframeAreaChart, setSelectedTimeframeAreaChart] =
    useState("weekly");

  const timeframeOptions = [
    { value: "today", label: "Today" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  const subjectOptions = [
    { value: "all", label: "Subjects" },
    { value: "math", label: "Mathematics" },
    { value: "physics", label: "Physics" },
    { value: "chemistry", label: "Chemistry" },
  ];

  const recentResults = [
    { subject: "Chemistry", score: 85.7, date: "10 Aug, 2024" },
    { subject: "Mathematics", score: 48.3, date: "09 Aug, 2024" },
    { subject: "Physics", score: 45.9, date: "08 Aug, 2024" },
    { subject: "Biology", score: 88.5, date: "07 Aug, 2024" },
    { subject: "Hindi", score: 95.0, date: "06 Aug, 2024" },
    { subject: "Social Studies", score: 91.2, date: "05 Aug, 2024" },
  ];

  return (
    <Flex vertical style={{ width: "100%", marginTop: 16 }}>
      <Flex style={{ width: "100%" }}>
        <HoursActivityChartCard
          timeframeOptions={timeframeOptions}
          subjectOptions={subjectOptions}
        />
        <RecentResultCard recentResults={recentResults} />
      </Flex>
      <Flex style={{ width: "100%", marginTop: 16 }}>
        <OverallProgressChartCard
          timeframeOptions={timeframeOptions}
          subjectOptions={subjectOptions}
          selectedTimeframeAreaChart={selectedTimeframeAreaChart}
          setSelectedTimeframeAreaChart={setSelectedTimeframeAreaChart}
        />
      </Flex>
    </Flex>
  );
};

export default DashboardReports;
