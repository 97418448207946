import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import { theme } from "./constants";
import { MainLayout } from "./layout";
import { RouteWrapper } from "./utils";
import {
  Signup,
  Signin,
  ForgotPassword,
  ForgotEmail,
  ResetPassword,
  ResetEmail,
  Book,
  Dashboard,
  Profile,
  Reports,
  Settings,
  Notifications,
  Onboarding,
} from "./pages";
import {
  QuizResultDisplay,
  ProtectedRoute,
  OnboardingRoute,
} from "./components";
import { SelectedChaptersProvider } from "./contexts";
import Landing from "./pages/landing/landing";

function App() {
  return (
    <SelectedChaptersProvider>
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={theme}>
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Landing />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/forgot-email" element={<ForgotEmail />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-email" element={<ResetEmail />} />

              {/* Onboarding route */}
              <Route element={<OnboardingRoute />}>
                <Route path="/onboarding" element={<Onboarding />} />
              </Route>

              {/* Protected routes */}
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/home"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <Dashboard
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/book/:bookId"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Book />}
                          showChat={true}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/book"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Book />}
                          showChat={true}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/test/:bookId"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Book />}
                          showChat={false}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/result"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<QuizResultDisplay />}
                          showChat={false}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Profile />}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Reports />}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Settings />}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <MainLayout>
                      {({ contentRef, collapsed }) => (
                        <RouteWrapper
                          element={<Notifications />}
                          contentRef={contentRef}
                          collapsed={collapsed}
                        />
                      )}
                    </MainLayout>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </ConfigProvider>
      </ThemeProvider>
    </SelectedChaptersProvider>
  );
}

export default App;
