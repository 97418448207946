import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated, isOnboardingComplete } from "../utils";

const OnboardingRoute = () => {
  // if (!isAuthenticated()) {
  //   return <Navigate to="/signin" replace />;
  // }

  if (isOnboardingComplete()) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default OnboardingRoute;
