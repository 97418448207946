import React from "react";
import {
  Button,
  Layout,
  Typography,
  Space,
  Select,
  InputNumber,
  Switch,
  Row,
  Col,
  Collapse,
  theme,
} from "antd";
import { quizTypes, bookData } from "../../constants";
import { useSelectedChapters } from "../../contexts";
import { QuizInfoDisplay, SwitchCard } from "../../components";
import {
  SyncOutlined,
  RedoOutlined,
  StepForwardOutlined,
  ApiOutlined,
  CheckCircleOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";

const { useToken } = theme;
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

function QuizSettingsDisplay({
  quizType,
  onQuizTypeChange,
  difficulty,
  onDifficultyChange,
  numQuestions,
  onNumQuestionsChange,
  allQuestions,
  onAllQuestionsToggle,
  containsRedemptionQuestions,
  onRedemptionQuestionsToggle,
  containsSkippedQuestions,
  onSkippedQuestionsToggle,
  onStartQuiz,
}) {
  const { token } = useToken();
  const { selectedChapters, setSelectedChapters } = useSelectedChapters();

  const handleQuizTypeChange = (selectedTypes) => {
    if (selectedTypes.includes("Mixed")) {
      // If "Mixed" is selected, only allow "Mixed" to remain selected
      onQuizTypeChange(["Mixed"]);
    } else {
      // Otherwise, allow multiple selections as usual
      onQuizTypeChange(selectedTypes);
    }
  };

  const handleChaptersChange = (selectedChapters) => {
    setSelectedChapters(selectedChapters);
  };

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        padding: "24px",
        overflowY: "auto",
      }}
    >
      <Content>
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <QuizInfoDisplay />
          {/* Quiz Type Dropdown */}
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text strong>Type:</Typography.Text>
              <Select
                mode="multiple"
                value={quizType}
                onChange={handleQuizTypeChange}
                size="large"
                style={{ width: "100%", marginTop: "6px" }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                {quizTypes.map((type) => (
                  <Option key={type.value} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* Quiz Difficulty Dropdown */}
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text strong>Difficulty:</Typography.Text>
              <Select
                value={difficulty}
                onChange={onDifficultyChange}
                size="large"
                style={{ width: "100%", marginTop: "6px" }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                <Option value="Easy">Easy</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Hard">Hard</Option>
              </Select>
            </Col>
            {/* <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text strong>Chapters:</Typography.Text>
              <Select
                mode="multiple"
                value={selectedChapters}
                onChange={handleChaptersChange}
                size="large"
                style={{ width: "100%", marginTop: "6px" }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                {bookData.book.chapters.map((chapter) => (
                  <Option key={chapter.chapterNumber} value={chapter.title}>
                    {chapter.title}
                  </Option>
                ))}
              </Select>
            </Col> */}
          </Row>

          <Row gutter={[16, 16]}>
            {/* No. of Questions Input */}
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text strong>No. of Questions:</Typography.Text>
              <InputNumber
                min={1}
                value={numQuestions}
                onChange={onNumQuestionsChange}
                size="large"
                style={{ width: "100%", marginTop: "6px" }}
              />
            </Col>
          </Row>

          {/* Additional Settings Collapsible */}
          <Collapse style={{ marginTop: "16px", marginBottom: "16px" }}>
            <Panel header="Additional Settings" key="1">
              {/* Questions Section */}
              <Typography.Title level={4} style={{ marginTop: 0 }}>
                Questions
              </Typography.Title>
              <SwitchCard
                icon={<SyncOutlined />}
                title="Shuffle Questions"
                description="Let the system randomly select the questions for you."
                defaultChecked={true}
                onChange={(checked) =>
                  console.log("Shuffle Questions:", checked)
                }
              />
              <SwitchCard
                icon={<RedoOutlined />}
                title="Redemption Questions"
                description="Allow learners to attempt few incorrect questions."
                defaultChecked={containsRedemptionQuestions}
                onChange={(checked) => {
                  console.log("Redemption Questions:", checked);
                  onRedemptionQuestionsToggle(checked);
                }}
              />
              <SwitchCard
                icon={<StepForwardOutlined />}
                title="Skip Questions & Attempt Later"
                description="Allow learners to skip questions now and revisit them later after finishing other questions."
                defaultChecked={containsSkippedQuestions}
                onChange={(checked) => {
                  console.log("Skip Questions:", checked);
                  onSkippedQuestionsToggle(checked);
                }}
              />
              <SwitchCard
                icon={<ApiOutlined />}
                title="Adaptive Question Bank Mode"
                description="Generate a new set of questions every time to prevent copying and rote learning."
                defaultChecked={false}
                onChange={(checked) => console.log("Adaptive Mode:", checked)}
              />

              {/* Answers Section */}
              <Typography.Title level={4} style={{ marginTop: "24px" }}>
                Answers
              </Typography.Title>
              <SwitchCard
                icon={<CalculatorOutlined />}
                title="Custom Answer Weightage"
                description="Set custom marks for different types of questions."
                defaultChecked={false}
                onChange={(checked) =>
                  console.log("Custom Weightage:", checked)
                }
                isWeightage={true}
                quizTypes={quizType}
              />
              <SwitchCard
                icon={<CheckCircleOutlined />}
                title="Pass mark"
                description="This will let you set minimum points for your learner to pass the quiz."
                defaultChecked={false}
                onChange={(checked) => console.log("Pass Mark:", checked)}
                isPassMark={true}
              />
            </Panel>
          </Collapse>

          {/* Timer Switch */}
          <Row>
            <Col>
              <Typography.Text strong>
                Display all questions on one page:
              </Typography.Text>
              <Switch
                checked={allQuestions}
                onChange={onAllQuestionsToggle}
                style={{ marginLeft: 10 }}
              />
            </Col>
          </Row>

          {/* Start Quiz Button */}
          <Button
            type="primary"
            onClick={onStartQuiz}
            style={{ marginTop: 16, marginBottom: 16 }}
          >
            Start Quiz
          </Button>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizSettingsDisplay;
