import React from "react";
import { theme } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { format, subDays } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const OverallProgressAreaChart = ({ timeframe = "weekly" }) => {
  const { token } = theme.useToken();

  const getLabels = () => {
    switch (timeframe) {
      case "today":
        return ["12AM", "4AM", "8AM", "12PM", "4PM", "8PM"];
      case "weekly":
        return Array.from({ length: 7 }, (_, i) =>
          format(subDays(new Date(), i), "dd MMM")
        ).reverse();
      case "monthly":
        return Array.from({ length: 30 }, (_, i) =>
          format(subDays(new Date(), i), "dd MMM")
        ).reverse();
      default:
        return [];
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
          stepSize: 20,
          color: token.colorTextSecondary,
        },
        grid: {
          color: token.colorBorder,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: token.colorTextSecondary,
        },
        title: {
          display: true,
          text: "Time",
          color: token.colorTextSecondary,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => "Target",
          label: (context) => `${context.parsed.y}%`,
        },
      },
    },
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        fill: true,
        data: Array.from(
          { length: getLabels().length },
          () => Math.floor(Math.random() * 40) + 40
        ),
        borderColor: token.colorPrimary,
        backgroundColor: `${token.colorPrimary}20`,
        tension: 0.4,
      },
    ],
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Line options={options} data={data} />
    </div>
  );
};

export default OverallProgressAreaChart;
