import React from "react";
import PropTypes from "prop-types";
import { Layout, Flex, Space, Button, Typography, theme } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ASSETS } from "../../constants";
import {
  AppLogo,
  AvatarWithText,
  DrawerSection,
  navigationItems,
  mainItems,
  settingsItems,
} from "../../components";

const { Sider } = Layout;
const { useToken } = theme;

const Drawer = ({ collapsed, setCollapsed, isMobileView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useToken();

  const handleMenuClick = ({ key }) => navigate(key);

  const renderLogo = () => (
    <Space>
      <AppLogo height="45px" />
    </Space>
  );

  // A toggle button to collapse or expand the drawer
  const renderMenuToggleButton = () => (
    <Button
      type="text"
      icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      onClick={() => setCollapsed(!collapsed)}
      style={{
        fontSize: "16px",
        padding: 16,
        borderRadius: 12,
        backgroundColor: token.colorWhite,
      }}
    />
  );

  const renderCloseButton = () =>
    isMobileView && (
      <Space>
        <Button
          shape="circle"
          type="ghost"
          icon={
            <CloseOutlined
              style={{
                fontSize: 16,
                color: token.colorPrimary,
                cursor: "pointer",
              }}
            />
          }
          onClick={() => setCollapsed(!collapsed)}
        />
      </Space>
    );

  // A card that displays the user's profile information at the bottom of the drawer
  const renderUserProfileCard = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userName = userData
      ? `${userData.first_name} ${userData.last_name}`
      : "";
    const userEmail = userData?.email || "";
    const initials = userName
      ? userName.split(" ")[0][0] + userName.split(" ")[1][0]
      : "";

    return (
      <Flex
        align="center"
        justify="space-between"
        style={{
          padding: collapsed ? "8px" : "16px",
          backgroundColor: token.colorWhite,
          margin: "auto 16px 16px 16px",
          borderRadius: "16px",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Flex align="center" style={{ flex: 1 }}>
          <AvatarWithText initials={initials} avatarSize={32} />
          {!collapsed && (
            <Flex vertical style={{ marginLeft: "2px", flex: 1 }}>
              <Typography.Text ellipsis style={{ maxWidth: "130px" }}>
                {userName}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                ellipsis
                style={{ maxWidth: "130px" }}
              >
                {userEmail}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
        {!collapsed && <RightOutlined style={{ color: token.colorBlack }} />}
      </Flex>
    );
  };

  const siderStyle = {
    borderRight: token.border,
    backgroundColor: token.colorBgLayout,
    height: "100vh",
    overflow: "hidden",
    ...(isMobileView
      ? {
          position: "fixed",
          left: 0,
          top: 0,
        }
      : {
          position: "sticky",
          left: 0,
          top: 0,
        }),
    zIndex: 1000,
  };

  const getSiderWidth = () => {
    if (isMobileView) {
      return 300; // Fixed width for mobile
    }
    const viewportWidth = window.innerWidth;
    const desiredWidth = Math.floor(viewportWidth * 0.18);
    return Math.min(Math.max(desiredWidth, 250), 400);
  };

  return (
    <Sider
      trigger={null}
      breakpoint="lg"
      collapsedWidth={80}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      theme="light"
      width={getSiderWidth()}
      style={siderStyle}
    >
      <Flex vertical style={{ height: "100vh" }}>
        <Flex
          style={{ padding: "24px 14px 24px 8px", margin: 0 }}
          justify={collapsed ? "center" : "space-between"}
          align="center"
        >
          {!collapsed && renderLogo()} {/* Only show logo when not collapsed */}
          {isMobileView ? renderCloseButton() : renderMenuToggleButton()}
        </Flex>
        {/* Only show label when not collapsed */}
        <Flex vertical style={{ flex: 1 }}>
          <DrawerSection
            label={!collapsed && "Navigation"}
            items={navigationItems}
            selectedKeys={[location.pathname]}
            onItemClick={handleMenuClick}
            collapsed={collapsed}
          />
          <DrawerSection
            label={!collapsed && "Main"}
            items={mainItems}
            selectedKeys={[location.pathname]}
            onItemClick={handleMenuClick}
            collapsed={collapsed}
          />
          <DrawerSection
            label={!collapsed && "Settings"}
            items={settingsItems}
            selectedKeys={[location.pathname]}
            onItemClick={handleMenuClick}
            collapsed={collapsed}
          />
        </Flex>
        {renderUserProfileCard()}
      </Flex>
    </Sider>
  );
};

Drawer.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired,
};

export default Drawer;
