// Dummy quiz data
const quizData = [
    {
      question: "What is the capital of France?",
      options: [
        { text: "Italy", isCorrect: false },
        { text: "Paris", isCorrect: true },
        { text: "Riyadh", isCorrect: false },
        { text: "Dublin", isCorrect: false },
      ],
      isRedemption: false,
      isPreviouslySkipped: false,
    },
    {
      question: "Which planet is known as the Red Planet?",
      options: [
        { text: "Earth", isCorrect: false },
        { text: "Mars", isCorrect: true },
        { text: "Jupiter", isCorrect: false },
        { text: "Venus", isCorrect: false },
      ],
      isRedemption: false,
      isPreviouslySkipped: false,
    },
    {
      question: "What is the largest ocean on Earth?",
      options: [
        { text: "Atlantic Ocean", isCorrect: false },
        { text: "Indian Ocean", isCorrect: false },
        { text: "Pacific Ocean", isCorrect: true },
        { text: "Arctic Ocean", isCorrect: false },
      ],
      isRedemption: true,
      isPreviouslySkipped: false,
    },
    {
      question: "Who wrote 'Hamlet'?",
      options: [
        { text: "Charles Dickens", isCorrect: false },
        { text: "Leo Tolstoy", isCorrect: false },
        { text: "William Shakespeare", isCorrect: true },
        { text: "Mark Twain", isCorrect: false },
      ],
      isRedemption: false,
      isPreviouslySkipped: true,
    },
    {
      question: "Which element has the chemical symbol 'O'?",
      options: [
        { text: "Oxygen", isCorrect: true },
        { text: "Gold", isCorrect: false },
        { text: "Hydrogen", isCorrect: false },
        { text: "Osmium", isCorrect: false },
      ],
      isRedemption: false,
      isPreviouslySkipped: false,
    },
  ];
  
  export default quizData;
  