import React from "react";
import { Flex, Button, Form, Input, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { AppLogo } from "../../../components";

const { Title, Text } = Typography;

export default function ResetPassword() {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values:", values);
    // Handle password reset logic here
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        width: "100vw",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Flex
        vertical
        align="center"
        style={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <AppLogo height="70px" />
        </Flex>

        <Flex
          vertical
          align="center"
          style={{
            backgroundColor: "#fff",
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
            padding: "40px",
            boxShadow: "0 8px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Title level={1} style={{ margin: 0 }}>
            Create New Password
          </Title>
          <Text style={{ marginTop: "10px", textAlign: "center" }}>
            Enter New Password
          </Text>
          <Divider />

          <Form
            form={form}
            name="reset_password"
            onFinish={onFinish}
            layout="vertical"
            style={{ width: "100%", maxWidth: "300px" }}
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long!",
                },
              ]}
            >
              <Input.Password
                placeholder="New Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "#5952d7" }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: "#5952d7" }} />
                  )
                }
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm New Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "#5952d7" }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: "#5952d7" }} />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", backgroundColor: "#5952d7" }}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>

          <Flex justify="center" style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "14px", color: "#5952d7" }}>
              {" "}
              Back to{" "}
              <Link to="/signin">
                <Text style={{ color: "#5952d7" }} strong>
                  Sign In
                </Text>
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
