import React from "react";
import styled from "styled-components";

const ShadowContainer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: ${props => props.left || '-20%'};
  bottom: ${props => props.bottom || '-20%'};
  opacity: 1;
`;

const BlueShadow = ({ left, bottom }) => {
  return (
    <ShadowContainer left={left} bottom={bottom}>
      <img 
        src="images/blue-shadow.svg" 
        alt="Shadow Effect"
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </ShadowContainer>
  );
};

export default BlueShadow; 