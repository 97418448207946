import React from "react";
import { Layout, Space, Button, Tooltip, Divider, theme } from "antd";
import { SwapOutlined, MoreOutlined } from "@ant-design/icons";
import { Chat, Quiz } from "../../book";
import { AvatarWithText } from "../../../components";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { useToken } = theme;

function ContentBox({ bookData, showChat }) {
  const { token } = useToken();
  const navigate = useNavigate();

  return (
    <Layout
      style={{
        marginLeft: "12px",
        borderRadius: "16px",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: token.colorWhite,
      }}
    >
      {/* <Content style={{ flex: "none" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "12px",
          }}
        >
          <AvatarWithText
            text={bookData.title}
            image={bookData.coverUrl}
            isBadge={false}
          />
          <Space>
            <Tooltip title={showChat ? "Switch to Quiz" : "Switch to Chat"}>
              <Button
                shape="circle"
                type="ghost"
                icon={<SwapOutlined />}
                onClick={() => {
                  showChat ? navigate("/test") : navigate("/book");
                }}
              />
            </Tooltip>
            <Tooltip title="More">
              <Button shape="circle" type="ghost" icon={<MoreOutlined />} />
            </Tooltip>
          </Space>
        </div>
        <Divider style={{ margin: "0", borderColor: token.borderColor }} />
      </Content> */}

      <Content style={{ flex: 1, overflow: "hidden" }}>
        {showChat ? <Chat /> : <Quiz />}
      </Content>
    </Layout>
  );
}

export default ContentBox;
