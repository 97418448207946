import React from "react";
import { Card, Row, Col, Typography, theme } from "antd";
import { BookOutlined, ReadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const { Title, Text } = Typography;
const { useToken } = theme;

function BookOrQuizSelection() {
  const { token } = useToken();
  const navigate = useNavigate();
  const { bookId } = useParams();

  const cardStyle = {
    border: token.border,
    borderColor: token.borderColor,
    borderRadius: "16px",
    textAlign: "center",
    cursor: "pointer",
    transition: "box-shadow 0.3s, border-color 0.3s",
  };

  return (
    <Row justify="center" align="middle" style={{ height: "90vh" }}>
      <Col span={6}>
        <Card
          style={cardStyle}
          hoverable
          onClick={() => navigate(`/book/${bookId}`)}
          bodyStyle={{ padding: "24px" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = "0 8px 24px rgba(0, 0, 0, 0.15)";
            e.currentTarget.style.borderColor = token.colorPrimary;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = "none";
            e.currentTarget.style.borderColor = token.borderColor;
          }}
        >
          <BookOutlined
            style={{ fontSize: "48px", color: token.colorPrimary }}
          />
          <Title level={4}>Book</Title>
          <Text type="secondary">Explore the book content</Text>
        </Card>
      </Col>
      <Col span={6} offset={2}>
        <Card
          style={cardStyle}
          hoverable
          onClick={() => navigate(`/test/${bookId}`)}
          bodyStyle={{ padding: "24px" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = "0 8px 24px rgba(0, 0, 0, 0.15)";
            e.currentTarget.style.borderColor = token.colorPrimary;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = "none";
            e.currentTarget.style.borderColor = token.borderColor;
          }}
        >
          <ReadOutlined
            style={{ fontSize: "48px", color: token.colorPrimary }}
          />
          <Title level={4}>Quiz</Title>
          <Text type="secondary">Take the quiz</Text>
        </Card>
      </Col>
    </Row>
  );
}

export default BookOrQuizSelection;
