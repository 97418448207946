const AskYourBookDrawerIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_344_382)">
        <path
          d="M3 4.5H1.5V15C1.5 15.825 2.175 16.5 3 16.5H13.5V15H3V4.5ZM15 1.5H6C5.175 1.5 4.5 2.175 4.5 3V12C4.5 12.825 5.175 13.5 6 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5ZM15 12H6V3H15V12ZM7.5 6.75H13.5V8.25H7.5V6.75ZM7.5 9H10.5V10.5H7.5V9ZM7.5 4.5H13.5V6H7.5V4.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_344_382">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AskYourBookDrawerIcon;
