import { AlignCenterOutlined, MenuFoldOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Button } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { ContentBox, BookInformation } from "../book";

const { Content } = Layout;

function Book({ contentRef, collapsed, showChat }) {
  const { bookId } = useParams();
  const navigate = useNavigate();
  const [bookData, setBookData] = useState(null);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);

  useEffect(() => {
    const booksList = JSON.parse(localStorage.getItem("booksList"));
    if (!booksList) return;

    if (bookId) {
      const book = booksList.ebooks.find((b) => b.id === parseInt(bookId, 10));
      if (book) {
        setBookData({
          ...book,
          coverUrl: "https://bwseducationconsulting.com/wp-content/uploads/2020/08/bws-maths-tutoring.jpg",
          isbn: "123-456-789",
        });
        setSelectedBookId(book.id);
      }
    } else {
      setBookData({
        ...booksList.ebooks[0],
        coverUrl: "https://bwseducationconsulting.com/wp-content/uploads/2020/08/bws-maths-tutoring.jpg",
        isbn: "123-456-789",
      });
      setSelectedBookId(booksList.ebooks[0].id);
    }
  }, [bookId]);

  const handleBookSelect = (book) => {
    setBookData(book);
    setSelectedBookId(book.id);
    navigate(`/book/${book.id}`);
  };

  const toggleDrawer = () => setDrawerCollapsed(!drawerCollapsed);

  if (!bookData) {
    return <div>Loading...</div>;
  }

  return (
    <Layout style={{backgroundColor: "white"}}>
      <Content
        style={{
          padding: "12px",
          width: "100%",
          height: "100%",
          backgroundColor: "FFFFFF"
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={drawerCollapsed ? 2 : 8} lg={drawerCollapsed ? 2 : 8}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "12px",
                padding: "12px",
                height: "100%",
                border: "1px solid #F0F2F2",
              }}
            >
              {/* Header Section */}
              <Row align="middle" justify="space-between" style={{paddingBottom: "12px"}}>
                {!drawerCollapsed && (
                <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#FF6C42",
                    color: "#FFFFFF",
                    borderRadius: "12px",
                    marginRight: "18px",
                    height: "38px",
                    width: "38px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/images/filter_icon.svg"
                    alt="Filter Icon"
                    style={{
                      height: "18px", // Adjust size as needed
                      width: "18px",  // Adjust size as needed
                    }}
                  />
                </div>
                <span style={{ fontWeight: "600", fontSize: "14px" }}>Filter</span>
              </div>
                )}

                <Button
                  type="text"
                  style={{
                    border: "1px solid #D4FCFC",
                    borderRadius: "8px",
                    backgroundColor: "#FFFFFF",
                    height: '36px',
                    width: '36px'
                  }}
                  onClick={toggleDrawer}
                >
                  <MenuFoldOutlined />
                </Button>
              </Row>

              {/* Book Information Drawer */}
              {!drawerCollapsed && (
                <BookInformation
                  bookData={bookData}
                  onBookSelect={handleBookSelect}
                  selectedBookId={selectedBookId}
                  collapsed={drawerCollapsed}
                />
              )}
            </div>
          </Col>
          <Col
            xs={24}
            md={drawerCollapsed ? 22 : 16}
            lg={drawerCollapsed ? 22 : 16}
          >
            <ContentBox bookData={bookData} showChat={showChat} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Book;
