import React from "react";
import { Layout, Typography, Button, theme } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { Text, Title } = Typography;
const { useToken } = theme;

const Container = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 auto;
  max-width: 400px;
  margin-top: 8vh;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 0;
    text-align: center;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StudentGraphic = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.1));

  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 24px;
  }
`;

const ShineLines = styled.img`
  position: absolute;
  bottom: 10%;
  left: 5%;
  width: 50px;
  height: 50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    bottom: 5%;
  }
`;

const CurvedLine = styled.img`
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 50px;
  height: 50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    bottom: 5%;
  }
`;

const BestText = styled.span`
  position: relative;
  color: ${(props) => props.theme.token.logoBlue};

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: -5%;
    width: 110%;
    height: 15px;
    background-image: url("images/best-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 132px;
  height: 48px;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 12px;
  border: none;

  @media (max-width: 768px) {
    margin: 0 auto;
    display: block;
  }
`;

const LandingContent = () => {
  const { token } = useToken();
  const navigate = useNavigate();

  return (
    <Layout style={{ background: "transparent" }}>
      <Content>
        <Container>
          <ContentWrapper>
            <LeftColumn>
              <Title
                level={1}
                style={{
                  fontSize: "56px",
                  fontWeight: 600,
                  lineHeight: "64px",
                  color: token.colorBlack,
                  marginBottom: "24px", // Increased spacing
                }}
              >
                Performance at its <BestText theme={{ token }}>Best!</BestText>
              </Title>
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: token.textColorGrey,
                  display: "block",
                  marginBottom: "32px", // Increased spacing
                }}
              >
                Smart AI tools to help you prepare, practice, and perform your
                best in exams.
              </Text>
              <StyledButton 
                type="primary"
                onClick={() => navigate("/signup")}
              >
                Signup for Free
              </StyledButton>
            </LeftColumn>

            <RightColumn>
              <StudentGraphic
                src="images/landing-student-graphic-2.png"
                alt="Student"
              />
            </RightColumn>
          </ContentWrapper>

          <ShineLines
            src="images/shine-three-lines.svg"
            alt="Decorative lines"
          />
          <CurvedLine
            src="images/curved-line-border.svg"
            alt="Decorative curve"
          />
        </Container>
      </Content>
    </Layout>
  );
};

export default LandingContent;
