import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Checkbox,
  message,
  Layout,
  Row,
  Col,
} from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../services";
import { setAuth } from "../../../utils";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ASSETS } from "../../../constants";
import { AppLogo } from "../../../components";
import { WelcomeScreen } from "../../../pages";

const { Content } = Layout;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color when unchecked
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color on hover when unchecked
  }
`;

export default function Signin() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);

  const handleComplete = () => {
    console.log("Navigating to next screen");
    navigate("/onboarding");
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const credentials = { email: values.email, password: values.password };
      const response = await loginUser(credentials);

      if (response.token) {
        setAuth(response.token, response.user);
        message.success("Login successful!");
        setShowWelcome(true);
      } else {
        throw new Error(response.message || "Invalid credentials");
      }
    } catch (error) {
      message.error("Login failed: " + (error.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (values) => {
    if (!values.email || !values.password) {
      message.error("Please fill in all fields!");
      return false;
    }
    return true;
  };

  const leftSectionRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  // Calculate container height on mount and resize
  useEffect(() => {
    const updateHeight = () => {
      if (leftSectionRef.current) {
        setContainerHeight(leftSectionRef.current.offsetHeight);
      }
    };
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  if (showWelcome) {
    return <WelcomeScreen onComplete={handleComplete} />;
  }

  return (
    <Layout style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <Row gutter={0} style={{ height: "100%" }}>
        {/* Left Section (Only visible on larger screens) */}
        <Col xs={0} sm={0} md={12} lg={12} xl={12}>
          <div
            ref={leftSectionRef}
            style={{
              backgroundColor: "#110D1C",
              borderRadius: "36px",
              padding: "36px",
              position: "relative",
              height: "100%",
              clipPath: "inset(0% 0% 0% 0%)", // Ensure the SVGs are clipped inside the container
              overflow: "hidden", // Prevent the SVGs from overflowing
            }}
          >
            {/* Animating Text */}
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.5,
                duration: 1.2,
                type: "spring",
                stiffness: 100,
              }}
              style={{
                fontSize: "36px",
                fontWeight: "300",
                color: "#fff",
                lineHeight: "1.2",
                textAlign: "left",
              }}
            >
              Ready to dive into learning magic? <br />
              Log in and let's make studying <br />
              seriously fun!
            </motion.div>

            {/* Falling SVGs */}
            {/* Falling SVGs */}
            <motion.div
              initial={{ y: "-100vh" }}
              animate={{ y: containerHeight * 0.75 }}
              transition={{
                delay: 1,
                duration: 1.5,
                type: "spring",
                stiffness: 100,
              }}
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                zIndex: 1,
              }}
            >
              {/* CS Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.2,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.csBookCard}
                  alt="CS"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>

              {/* LA Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.4,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.laBookCard}
                  alt="LA"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>

              {/* PHY Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.6,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.phyBookCard}
                  alt="PHY"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>
            </motion.div>
          </div>
        </Col>

        {/* Right Section */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "36px",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {/* Logo Section */}
            <div
              style={{
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              <AppLogo height="70px" />
            </div>

            {/* Middle Section */}
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <img
                src={ASSETS.waveHand}
                alt="Waving Emoji"
                style={{ height: 50, marginBottom: 10 }}
              />
              <div
                style={{ fontSize: 32, fontWeight: "400", marginBottom: 10 }}
              >
                Welcome Back
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  color: "#6B7B82",
                  marginBottom: 30,
                }}
              >
                Enter your credentials to access your account.
              </div>

              <Form
                form={form}
                name="signin"
                onFinish={onFinish}
                layout="vertical"
                style={{ maxWidth: 350, margin: "0 auto" }}
                colon={false} // Removes asterisks
                onFinishFailed={() => validateForm(form.getFieldsValue())} // Handle validation failure
              >
                {/* Email Field */}
                <Form.Item
                  label={
                    <span style={{ fontSize: 14, fontWeight: 400 }}>Email</span>
                  }
                  name="email"
                >
                  <Input
                    placeholder="Enter your email"
                    style={{
                      backgroundColor: "#F5F7FA",
                      height: 50,
                      borderRadius: 8,
                      fontSize: 12,
                      border: "none",
                    }}
                  />
                </Form.Item>

                {/* Password Field */}
                <Form.Item
                  label={
                    <span style={{ fontSize: 14, fontWeight: 400 }}>
                      Password
                    </span>
                  }
                  name="password"
                  rules={[{ message: "Please input your password!" }]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    style={{
                      backgroundColor: "#F5F7FA",
                      height: 50,
                      borderRadius: 8,
                      fontSize: 12,
                      border: "none",
                    }}
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                {/* Remember Me and Forgot Password */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <CustomCheckbox>Remember Me</CustomCheckbox>
                  <Link
                    to="/forgot-password"
                    style={{
                      fontSize: 14,
                      color: "#C34244",
                      fontWeight: "600",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>

                {/* Sign-In Button */}
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#FF6C42",
                    borderColor: "#FF6C42",
                    height: 48,
                    borderRadius: 8,
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                  loading={loading}
                >
                  Sign In
                </Button>

                {/* Continue With Google */}
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "1px solid #ccc",
                    height: 48,
                    borderRadius: 8,
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 10,
                  }}
                >
                  <img
                    src={ASSETS.googleLogo}
                    alt="Google"
                    style={{ marginRight: 8, height: 20 }}
                  />
                  Continue With Google
                </Button>
              </Form>
            </div>

            {/* Sign-up Link */}
            <div style={{ textAlign: "center" }}>
              <Typography.Text>
                Don't have an account?{" "}
                <Link to="/signup">
                  <Typography.Text strong style={{ color: "#000" }}>
                    Signup
                  </Typography.Text>
                </Link>
              </Typography.Text>
            </div>
          </Content>
        </Col>
      </Row>
    </Layout>
  );
}
