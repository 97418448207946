import React from "react";
import { Typography, Flex, theme } from "antd";
import styled from "styled-components";
import ListItem from "./list-item";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 80px;
    right: 250px;
    width: 44px;
    height: 44px;
    background-image: url('images/benefits-star.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 768px) {
      top: 150px;
      right: 20px;
      width: 32px;
      height: 32px;
    }
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
    align-items: center;
    text-align: center;
  }
`;

const EasierText = styled.span`
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: -5%;
    width: 110%;
    height: 15px;
    background-image: url('images/easier-underline.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;

  &::before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    width: 70px;
    height: 70px;
    background-image: url('images/three-leaves.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 768px) {
      top: -70px;
      left: 0;
      width: 50px;
      height: 50px;
    }
  }
`;

const GraphicContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const BenefitsSection = () => {
  const { token } = useToken();

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 600,
            marginBottom: "16px",
            ...(window.innerWidth <= 768 && { fontSize: "36px" }),
          }}
        >
          Signup Made <EasierText>Easier</EasierText>
        </Title>
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 500,
            color: token.textColorGrey,
            maxWidth: "600px",
          }}
        >
          Experience the unbelievable in just 3 steps. Signup made easier than ever. 
        </Text>
      </HeaderSection>

      <ContentWrapper gap={64}>
        <div style={{ flex: 1 }}>
          <ListContainer>
            <ListItem
              title="Easy basic registeration"
              description="Say bye to the boring & long filling signup forms. Use Google or Any email to signup for free."
              iconColor="colorPrimary"
            />
            <ListItem
              title="Select Grade & Books"
              description="Let AI know a little about yourself."
              iconColor="textColorGrey"
            />
            <ListItem
              title="Start preparing & practicing"
              description="And then let AI take you for a ride to the front of the line."
              iconColor="borderColorLime" 
            />
          </ListContainer>
        </div>
        <GraphicContainer>
          <img 
            src="images/benefits-section-graphic.svg" 
            alt="Benefits"
            style={{ 
              maxWidth: '100%', 
              height: 'auto',
              position: 'relative',
              zIndex: 1
            }}
          />
        </GraphicContainer>
      </ContentWrapper>
    </SectionContainer>
  );
};

export default BenefitsSection; 