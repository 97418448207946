import React from "react";
import { Card, Flex, Typography, Divider, Progress, theme } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { TextBadge } from "../../../components";
import { ChaptersIconBookCard } from "../../../assets";
const { Text } = Typography;
const { useToken } = theme;

const BookCard = ({
  imageUrl,
  grade,
  bookName,
  chaptersCount,
  timeTaken,
  progress,
}) => {
  const { token } = useToken();

  return (
    <Card
      style={{
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        border: "none",
      }}
      bodyStyle={{ padding: "0px" }}
    >
      <Flex vertical>
        {/* Image Section with Grade Badge */}
        <div
          style={{
            position: "relative",
            padding: "16px",
          }}
        >
          <img
            src={
              imageUrl ||
              "https://bwseducationconsulting.com/wp-content/uploads/2020/08/bws-maths-tutoring.jpg"
            }
            alt={bookName}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />

          <div
            style={{
              position: "absolute",
              top: "32px",
              right: "32px",
            }}
          >
            <TextBadge
              text={`Grade ${grade}`}
              backgroundColor={token.colorLightGreen}
              textColor={token.colorDarkGreen}
              isBorder={false}
            />
          </div>
        </div>

        {/* Book Name */}
        <Text
          strong
          style={{
            fontSize: "16px",
            marginBottom: "16px",
            padding: "0px 16px",
          }}
        >
          {bookName}
        </Text>

        <Divider style={{ margin: "0" }} />

        {/* Bottom Section */}
        <Flex
          justify="space-between"
          align="center"
          style={{ padding: "16px" }}
        >
          {/* Left Side Stats */}
          <Flex gap={24}>
            <Flex align="center" gap={8}>
              <ChaptersIconBookCard color={token.colorTextSecondary} />
              <Text style={{ color: token.colorGrey }}>
                {chaptersCount} CHAPTERS
              </Text>
            </Flex>
            <Flex align="center" gap={8}>
              <ClockCircleOutlined
                style={{ color: token.colorTextSecondary }}
              />
              <Text style={{ color: token.colorGrey }}>{timeTaken} HR</Text>
            </Flex>
          </Flex>

          {/* Right Side Progress */}
          <Flex align="center" gap={8}>
            <Progress
              type="circle"
              percent={progress}
              width={24}
              strokeColor={token.colorPrimary}
              trailColor={token.colorPrimaryBg}
              showInfo={false}
            />
            <Text style={{ color: token.colorGrey }}>{progress}%</Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default BookCard;
