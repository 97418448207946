import React, { useState } from "react";
import { Flex, Tooltip, Button } from "antd";
import {
  SoundOutlined,
  CopyOutlined,
  CheckCircleTwoTone,
  LikeOutlined,
  DislikeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { speakAloud, copyToClipboard } from "../../utils";

function MessageOption({ content, index }) {
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const handleSpeakAloud = (message) => {
    speakAloud(message);
  };

  const handleCopyToClipboard = (message, index) => {
    copyToClipboard(message);
    setCopiedMessageId(index);
    setTimeout(() => setCopiedMessageId(null), 2000);
  };

  return (
    <Flex>
      <Tooltip title="Speak Aloud">
        <Button
          shape="circle"
          type="ghost"
          icon={<SoundOutlined />}
          onClick={() => handleSpeakAloud(content)}
        />
      </Tooltip>
      <Tooltip title={copiedMessageId === index ? "Copied!" : "Copy"}>
        <Button
          shape="circle"
          type="ghost"
          icon={
            copiedMessageId === index ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <CopyOutlined />
            )
          }
          onClick={() => handleCopyToClipboard(content, index)}
          style={{
            transition: "transform 0.2s ease-in-out",
            transform: copiedMessageId === index ? "scale(1.2)" : "scale(1)",
          }}
        />
      </Tooltip>
      <Tooltip title="Good Response">
        <Button shape="circle" type="ghost" icon={<LikeOutlined />} />
      </Tooltip>
      <Tooltip title="Bad Response">
        <Button shape="circle" type="ghost" icon={<DislikeOutlined />} />
      </Tooltip>
      <Tooltip title="Try Again">
        <Button shape="circle" type="ghost" icon={<ReloadOutlined />} />
      </Tooltip>
    </Flex>
  );
}

export default MessageOption;
