import { Card, Flex, Typography, Avatar, theme } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import React, { useState } from "react";

const { Paragraph } = Typography;
const { useToken } = theme;

const QuizOptionCard = ({ optionNumber, text, isCorrect, onClick, highlighted, showCorrect, isWrong }) => {
  const [hovered, setHovered] = useState(false);
  const { token } = useToken();

  const iconStyle = {
    marginLeft: "auto",
    display: "flex",
    transition: "opacity 0.3s ease, visibility 0.3s ease",
    fontSize: "24px",
  };

  return (
    <Card
      style={{
        borderRadius: "8px",
        padding: "12px",
        marginBottom: "8px",
        border: token.border,
        borderColor: isWrong ? "#ff4d4f" : highlighted ? token.colorPrimary : token.borderColor,
        transition: "border 0.3s ease",
        cursor: "pointer",
        backgroundColor: highlighted && !showCorrect // Only apply background if quiz isn't submitted
          ? "#e6f7ff"
          : showCorrect && isCorrect
          ? "#e6fffb"
          : "inherit", // Show green for correct answers after submission
      }}
      bodyStyle={{ padding: 0 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
    <Flex
      align="center"
      style={{
        width: "100%",
        paddingInline: "8px",
        whiteSpace: "nowrap",
      }}
    >
    <Avatar
        style={{
          backgroundColor: token.colorPrimaryBg,
          color: token.colorPrimary,
          marginRight: 12,
          flexShrink: 0,
        }}
      >
        {optionNumber}
      </Avatar>
      <Paragraph style={{ margin: 0 }}>{text}</Paragraph>
      {showCorrect && isCorrect ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={iconStyle} />
      ) : showCorrect && !isCorrect && isWrong ? ( // Show close icon for wrong answers
        <CloseCircleTwoTone twoToneColor="#eb2f96" style={iconStyle} />
      ) : null}
    </Flex>
  </Card>

  );
};

export default QuizOptionCard;
