import { Flex, Typography, theme } from "antd";
import { SubjectIconRecentResults } from "../../assets";
import { ColoredDecimalNumber } from "../../components";

const { Text } = Typography;
const { useToken } = theme;

const RecentResultCard = ({ recentResults }) => {
  const { token } = useToken();

  const RecentResultItem = ({ subject, score, date }) => (
    <Flex align="center" style={{ marginBottom: 16 }}>
      <Flex
        justify="center"
        align="center"
        style={{
          padding: 12,
          borderRadius: 12,
          backgroundColor: token.colorBgLayout,
        }}
      >
        <SubjectIconRecentResults color={token.colorPrimary} />
      </Flex>
      <Flex vertical style={{ marginLeft: 12 }}>
        <Text strong>{subject}</Text>
        <Text type="secondary">{date}</Text>
      </Flex>
      <div style={{ marginLeft: "auto" }}>
        <ColoredDecimalNumber
          number={score}
          color={score > 50 ? token.colorGreen : token.colorPrimary}
          size={20}
        />
      </div>
    </Flex>
  );

  return (
    <Flex
      vertical
      style={{
        flex: 1,
        backgroundColor: token.colorWhite,
        padding: 24,
        borderRadius: 16,
        marginLeft: 8,
        height: 350,
      }}
    >
      <Text strong style={{ fontSize: "16px", marginBottom: 24 }}>
        Recent Results
      </Text>
      <Flex
        vertical
        style={{
          height: 300,
          overflowY: "auto",
          paddingRight: 8,
        }}
      >
        {recentResults.map((result, index) => (
          <RecentResultItem key={index} {...result} />
        ))}
      </Flex>
    </Flex>
  );
};

export default RecentResultCard;
