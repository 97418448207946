import { Flex, Typography, theme } from "antd";
import { ASSETS } from "../../constants";
const { useToken } = theme;
const { Text } = Typography;

const ArrowTextCard = ({
  text,
  isMirrored,
  isFlipped,
  rotation,
  arrowMargin,
}) => {
  const { token } = useToken();
  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{ width: "100%", margin: "10px 0" }}
    >
      {isFlipped && (
        <>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={ASSETS.coveredCircleTextGraphic}
              alt="Circle Text Graphic"
              style={{ display: "block" }}
            />
            <Text
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "50%",
                left: "45%",
                transform: "translate(-50%, -50%)",
                color: token.textColorGreen,
                textAlign: "center",
              }}
            >
              {text}
            </Text>
          </div>
          <div style={{ height: "10px" }}></div>
        </>
      )}
      <img
        src={ASSETS.coveredArrow}
        alt="Arrow"
        style={{
          marginRight: isMirrored ? arrowMargin : 0,
          marginLeft: isMirrored ? 0 : arrowMargin,
          height: "50px",
          transform: `
      ${isMirrored ? "scaleX(-1)" : ""}
      rotate(${isFlipped ? 180 + rotation : rotation}deg)
    `,
        }}
      />
      {!isFlipped && (
        <>
          <div style={{ height: "10px" }}></div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={ASSETS.coveredCircleTextGraphic}
              alt="Circle Text Graphic"
              style={{ display: "block" }}
            />
            <Text
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "50%",
                left: "45%",
                transform: "translate(-50%, -50%)",
                color: token.textColorGreen,
                textAlign: "center",
              }}
            >
              {text}
            </Text>
          </div>
        </>
      )}
    </Flex>
  );
};

export default ArrowTextCard;
