import React from "react";
import {
  Layout,
  Breadcrumb,
  Card,
  Typography,
  Flex,
  Row,
  Col,
  Switch,
  Button,
  Divider,
} from "antd";
import { HomeOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import { AvatarWithText } from "../../components";
import { theme } from "antd";

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

const SwitchWithText = ({ text, defaultChecked }) => (
  <Flex align="center" style={{ marginBottom: "10px" }}>
    <Switch defaultChecked={defaultChecked} style={{ marginRight: "10px" }} />
    <Text>{text}</Text>
  </Flex>
);

const InfoItem = ({ label, value }) => (
  <Flex style={{ marginBottom: "10px" }}>
    <Text strong style={{ color: "grey", marginRight: "5px" }}>
      {label}:
    </Text>
    <Text>{value}</Text>
  </Flex>
);

function Profile() {
  const { token } = useToken(); // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const fullName = `${userData.first_name} ${userData.last_name}`;

  return (
    <Layout style={{ padding: "0 24px 24px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <UserOutlined />
          <span>Profile</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Flex
          style={{
            backgroundColor: token.colorWhite,
            border: token.border,
            borderColor: token.borderColor,
            borderRadius: 12,
            padding: "24px",
            marginBottom: "24px",
          }}
        >
          <AvatarWithText
            text=""
            avatarSize={64}
            initials={fullName.split(" ")[0][0] + fullName.split(" ")[1][0]}
          />
          <Title level={3} style={{ marginLeft: "16px" }}>
            {fullName}
          </Title>
        </Flex>

        <Row gutter={24}>
          <Col span={12}>
            <Card>
              <Title level={4}>Platform Settings</Title>
              <Title level={5}>Account</Title>
              <SwitchWithText
                text="New launches and projects"
                defaultChecked={true}
              />
              <SwitchWithText
                text="Monthly product updates"
                defaultChecked={false}
              />
              <SwitchWithText
                text="Subscribe to newsletter"
                defaultChecked={true}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: "16px" }}
              >
                <Title level={4} style={{ margin: 0 }}>
                  Profile Information
                </Title>
                <Button shape="circle" icon={<EditOutlined />} ghost />
              </Flex>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Text>
              <Divider style={{ borderColor: token.borderColor }} />
              <InfoItem label="Full Name" value={fullName} />
              <InfoItem label="Mobile" value={userData.phone} />
              <InfoItem label="Email" value={userData.email} />
              <InfoItem label="Location" value="India" />
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Profile;
