import React from "react";
import { CheckOutlined } from "@ant-design/icons";

const Steps = ({ steps, currentStep }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: "#F7F7F8",
        borderRadius: "8px",
        padding: "12px",
        marginBottom: "24px", // Added margin below the Steps component
        boxSizing: "border-box",
      }}
    >
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {/* Step with Title */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "32px", // Icon size
                height: "32px",
                borderRadius: "50%",
                backgroundColor:
                  index < currentStep
                    ? "#8BC34A"
                    : index === currentStep
                    ? "#FF6C42"
                    : "#FFFFFF",
                color: index === currentStep ? "#FFFFFF" : "#FF6C42",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "normal", // No bold text
              }}
            >
              {index < currentStep ? (
                <CheckOutlined style={{ color: "#FFFFFF" }} />
              ) : (
                index + 1
              )}
            </div>
            <span
              style={{
                marginLeft: "8px", // Title on the right
                fontSize: "14px",
                color: "#000",
                textAlign: "center",
              }}
            >
              {step.title}
            </span>
          </div>

          {/* Connecting Line */}
          {index < steps.length - 1 && (
            <div
              style={{
                flex: 1,
                height: "1px", // Thinner line
                backgroundColor:
                  index < currentStep ? "#8BC34A" : "#D3D7D9", // Line after step
                margin: "0 8px",
              }}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Steps;
