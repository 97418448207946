const TakeATestDrawerIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_344_389)">
        <path
          d="M12 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5075C1.5 14.3325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V7.5L12 3ZM3 13.5075V4.5H11.25V8.25H15V13.5075H3Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_344_389">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TakeATestDrawerIcon;
