import React, { useState } from "react";
import {
  Layout,
  Card,
  Typography,
  AutoComplete,
  Button,
  theme,
  Checkbox,
  Input,
  Space,
  Form,
  Flex,
  Row,
  Col,
  message,
} from "antd";
import Steps from "./steps";
import {
  PlusOutlined,
  SearchOutlined,
  MinusOutlined,
  BookOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { updateOnboarding } from "../../services";
import { getAuth } from "../../utils";
import { ASSETS } from "../../constants";

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

const Logo = styled.img`
  width: 250px; /* Adjust size as needed */
  height: auto;
  margin: 20px auto; /* Center the logo horizontally */
  display: block; /* Ensure the logo is displayed as a block-level element */
`;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color when unchecked
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color on hover when unchecked
  }
`;

const StyledCard = styled(Card)`
  width: 500px;
  margin: 0 auto;
  position: relative;
  border: None;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .ant-card-body {
    padding: 24px;
  }

  margin-bottom: 24px;

  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;
  }
`;

const StyledContent = styled(Content)`
  position: relative;
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers content horizontally */
  justify-content: flex-start; /* Aligns items at the top */
  padding: 20px 0;
  background-color: white;
`;

function Onboarding() {
  const { token } = useToken();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [books, setBooks] = useState([{ name: "", publisher: "" }]);
  const [samePublisher, setSamePublisher] = useState(false);
  const [commonPublisher, setCommonPublisher] = useState("");
  const [grade, setGrade] = useState("");
  const [school, setSchool] = useState("");
  const [examBoard, setExamBoard] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const examBoardOptions = [{ value: "CBSE" }, { value: "ICSE" }];
  const gradeOptions = [{ value: "Grade 9" }, { value: "Grade 10" }];
  const schoolOptions = [{ value: "DPS" }, { value: "KV" }];
  const publisherOptions = [{ value: "Publisher 1" }, { value: "Publisher 2" }];

  const handleAddBook = () => {
    console.log("Adding book");
    console.log(books);
    setBooks([...books, { name: "", publisher: "" }]);
  };

  const handleCommonPublisher = (value) => {
    setCommonPublisher(value);
    const newBooks = books.map((book) => ({ ...book, publisher: value }));
    setBooks(newBooks);
  };

  const handleBookChange = (index, field, value) => {
    console.log("Changing book");
    const newBooks = [...books];
    newBooks[index][field] = value;
    console.log(newBooks);
    setBooks(newBooks);
  };

  const handleRemoveBook = (index) => {
    const newBooks = books.filter((_, i) => i !== index);
    setBooks(newBooks);
  };

  const handleSubmit = async () => {
    if (
      !grade ||
      !school ||
      !examBoard ||
      (samePublisher && !commonPublisher)
    ) {
      console.log(grade, school, examBoard, samePublisher, books);
      message.error("Please fill in all required fields.");
      return;
    }

    const payload = {
      exam_board:
        examBoardOptions.findIndex((option) => option.value === examBoard) + 1,
      grade: gradeOptions.findIndex((option) => option.value === grade) + 1,
      school: schoolOptions.findIndex((option) => option.value === school) + 1,
      same_book_publisher: samePublisher,
      ebook: books.map((_, index) => index + 1),
      subject_book_publisher: samePublisher
        ? [
            publisherOptions.findIndex(
              (option) => option.value === commonPublisher
            ) + 1,
          ]
        : books.map(
            (book) =>
              publisherOptions.findIndex(
                (option) => option.value === book.publisher
              ) + 1
          ),
      progress: 0,
      subscription_purchased: "trial",
      last_paid_date: "2024-11-04T00:00:00Z",
      trial_start_date: "2024-11-04T00:00:00Z",
      trial_end_date: "2024-12-04T00:00:00Z",
    };

    setIsSubmitting(true);

    try {
      const { authToken } = getAuth();
      console.log(payload);
      const response = await updateOnboarding(payload, authToken);

      if (response.error) {
        throw new Error(response.error);
      }

      const userData = JSON.parse(localStorage.getItem("userData"));
      userData.onboarding_complete = true;
      localStorage.setItem("userData", JSON.stringify(userData));

      message.success("You're all set! 🎉");
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      message.error("Failed to submit onboarding information.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGradeChange = (value) => {
    setGrade(value);
  };

  const handleSchoolChange = (value) => {
    setSchool(value);
  };

  const handleExamBoardChange = (value) => {
    setExamBoard(value);
  };

  const steps = [
    {
      title: "School",
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>Help Us Understand Your Learning Needs!</Text>
          <Text>
            Answer a few quick questions to help us create a learning experience
            just right for you!
          </Text>
          <Form.Item
            label="Select Grade"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
          >
            <AutoComplete
              options={gradeOptions}
              value={grade}
              onChange={handleGradeChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                placeholder="Select"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item
            label="Select School"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
          >
            <AutoComplete
              options={schoolOptions}
              value={school}
              onChange={handleSchoolChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                placeholder="Select"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item label="Select Board" wrapperCol={{ span: 24 }}>
            <AutoComplete
              options={examBoardOptions}
              value={examBoard}
              onChange={handleExamBoardChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                placeholder="Select"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>
        </Space>
      ),
    },
    {
      title: "Books",
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>Select books and their publisher.</Text>
          <Text>
            Choose your school books and publishers to help us match your
            resources!
          </Text>
          <Flex justify="space-between" align="center">
            <CustomCheckbox
              checked={samePublisher}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setSamePublisher(isChecked);
                if (!isChecked) {
                  setCommonPublisher("");
                }
              }}
            >
              All the books have same publisher
            </CustomCheckbox>
            <Button
              type="text"
              onClick={handleAddBook}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "20px", // Smaller button size
                  height: "20px", // Smaller button size
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#8BC34A",
                  color: "#ffffff",
                  fontSize: "10px", // Smaller icon size
                }}
              >
                <PlusOutlined />
              </div>
              <span
                style={{ fontSize: "14px", color: "#000000" /* Black text */ }}
              >
                Add Another Book
              </span>
            </Button>
          </Flex>

          {samePublisher ? (
            <Form.Item label="Publisher" wrapperCol={{ span: 24 }}>
              <AutoComplete
                onChange={handleCommonPublisher}
                options={publisherOptions}
                dropdownStyle={{ backgroundColor: "#fff" }}
              >
                <Input
                  prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                  style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                  dropdownStyle={{ backgroundColor: "#fff" }}
                  size="large"
                  placeholder="Search or Select"
                  value={commonPublisher}
                  options={publisherOptions}
                  variant="borderless"
                />
              </AutoComplete>
            </Form.Item>
          ) : null}

          {books.map((book, index) => (
            <Card
              key={index}
              style={{
                width: "100%",
                borderStyle: "dashed",
                borderWidth: "2px",
              }}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Form.Item
                  label="Book"
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    placeholder="Enter"
                    prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                    size="large"
                    value={book.name}
                    onChange={(e) =>
                      handleBookChange(index, "name", e.target.value)
                    }
                    style={{
                      width: "100%",
                      backgroundColor: "#F5F7FA",
                      fontSize: "14px",
                    }}
                    variant="borderless"
                  />
                </Form.Item>
                {!samePublisher && (
                  <Form.Item
                    label="Publisher"
                    wrapperCol={{ span: 24 }}
                    style={{ marginBottom: 0 }}
                  >
                    <AutoComplete
                      options={publisherOptions}
                      dropdownStyle={{ backgroundColor: "#fff" }}
                      onChange={(value) =>
                        handleBookChange(index, "publisher", value)
                      }
                    >
                      <Input
                        prefix={
                          <SearchOutlined style={{ paddingRight: "6px" }} />
                        }
                        style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                        dropdownStyle={{ backgroundColor: "#fff" }}
                        size="large"
                        placeholder="Search or Select"
                        value={book.publisher}
                        variant="borderless"
                      />
                    </AutoComplete>
                  </Form.Item>
                )}
                <Space direction="horizontal" style={{ marginTop: "8px" }}>
                  {/* Add Book Button */}
                  <Button
                    type="text"
                    onClick={handleAddBook}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      backgroundColor: "transparent",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "14px", // Smaller button size
                        height: "14px", // Smaller button size
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#8BC34A",
                        color: "#ffffff",
                        fontSize: "7px", // Smaller icon size
                      }}
                    >
                      <PlusOutlined />
                    </div>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginRight: 12,
                      }}
                    >
                      Add Book
                    </span>
                  </Button>

                  {/* Remove Button */}
                  <div
                    style={{
                      width: "14px",
                      height: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      backgroundColor: "#C34244",
                      color: "#ffffff",
                      fontSize: "7px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveBook(index)}
                  >
                    <MinusOutlined />
                  </div>
                  <span style={{ fontSize: "14px", color: "#000000" }}>
                    Remove
                  </span>
                </Space>
              </Space>
            </Card>
          ))}

          {/* Books Added Section */}
          {books.length > 0 && (
            <div style={{ marginTop: "4px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text>Books Added</Text>
                <Row gutter={[16, 16]}>
                  {books.map((book, index) => (
                    <Col xs={24} sm={12} key={index}>
                      <Card
                        style={{ backgroundColor: "#F5F7FA", padding: "8px" }}
                        bodyStyle={{ padding: 0 }}
                      >
                        <Flex
                          align="center"
                          justify="space-between"
                          style={{ width: "100%" }}
                        >
                          {/* Icon Section */}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#FF8968",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "8px",
                              flexShrink: 0,
                            }}
                          >
                            <BookOutlined
                              style={{ fontSize: "24px", color: "#FFFFFF" }}
                            />
                          </div>

                          {/* Text Section */}
                          <div style={{ flex: 1, marginLeft: "16px" }}>
                            <Typography.Text
                              strong
                              style={{ display: "block", fontSize: "16px" }}
                            >
                              {book.name || "Book Name"}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: "12px" }}
                            >
                              {samePublisher
                                ? commonPublisher
                                : book.publisher || "Publisher Name"}
                            </Typography.Text>
                          </div>

                          {/* Remove Button */}
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "8px",
                              borderRadius: "50%",
                              backgroundColor: "#C34244",
                              color: "#ffffff",
                              cursor: "pointer",
                              fontSize: "8px",
                            }}
                            onClick={() => handleRemoveBook(index)}
                          >
                            <MinusOutlined />
                          </div>
                        </Flex>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Space>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Finish",
      content: (
        <Space
          direction="vertical"
          style={{
            width: "100%",
            textAlign: "center",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <Text strong>Is Everything Correct?</Text>
          <Text>
            Take a moment to check your details. Confirm if they're correct, or
            return to edit if needed
          </Text>
        </Space>
      ),
    },
  ];

  return (
    <Layout
      xs={24}
      sm={24}
      style={{ minHeight: "100vh", backgroundColor: token.colorBgContainer }}
    >
      <StyledContent>
        <Logo src={ASSETS.logo} alt="Logo" />
        <StyledCard theme={{ token }}>
          <Steps steps={steps} currentStep={currentStep} />

          {steps[currentStep].content}

          <Flex justify="space-between" style={{ marginTop: 24 }}>
            {currentStep === 0 && (
              <Button
                onClick={() => navigate("/")}
                style={{
                  width: "120px",
                  backgroundColor: "#F0F2F2",
                  color: "#FF6C42",
                  border: "none",
                  outline: "none",
                  fontSize: "12px",
                }}
                onMouseOver={(e) => (e.target.style.border = "none")}
                onFocus={(e) => (e.target.style.border = "none")}
              >
                Skip
              </Button>
            )}
            {currentStep > 0 && (
              <Button
                onClick={() => setCurrentStep(currentStep - 1)}
                style={{
                  width: "120px",
                  backgroundColor: "#F0F2F2",
                  color: "#FF6C42",
                  border: "none",
                  outline: "none",
                  fontSize: "12px",
                }}
                onMouseOver={(e) => (e.target.style.border = "none")}
                onFocus={(e) => (e.target.style.border = "none")}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                if (currentStep < steps.length - 1) {
                  setCurrentStep(currentStep + 1);
                } else {
                  handleSubmit();
                }
              }}
              loading={currentStep === steps.length - 1 && isSubmitting}
              style={{
                marginLeft: "auto",
                width: "120px",
                backgroundColor: "#FF6C42",
                color: "#ffffff",
                border: "none",
                outline: "none",
                fontSize: "12px",
              }}
              onMouseOver={(e) => (e.target.style.border = "none")}
              onFocus={(e) => (e.target.style.border = "none")}
            >
              {currentStep < steps.length - 1 ? "Next" : "Finish"}
            </Button>
          </Flex>
        </StyledCard>
      </StyledContent>
    </Layout>
  );
}

export default Onboarding;
