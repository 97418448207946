import React from "react";
import { Card, Typography, Flex, theme } from "antd";
import { ColoredDecimalNumber, TextBadge } from "../../components";
import { CalendarOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

const ScoreCard = ({ title, score, subject, date }) => {
  const { token } = useToken();
  // Format date
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <Card
      style={{
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        padding: "24px",
        marginBottom: "16px",
        borderColor: token.colorBgLayout,
      }}
      bodyStyle={{ padding: "0px" }}
    >
      {/* Header Row */}
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "16px" }}
      >
        <Text strong style={{ fontSize: "16px" }}>
          {title}
        </Text>
        <TextBadge
          text={subject}
          backgroundColor={token.colorWhite}
          textColor={token.colorPrimary}
          borderColor={token.colorPrimary}
        />
      </Flex>

      {/* Score Row */}
      <Flex justify="center" style={{ marginBottom: "16px" }}>
        <ColoredDecimalNumber number={score} color={token.colorPrimary} />
      </Flex>

      {/* Date Row */}
      <Flex justify="center" align="center">
        <Flex
          justify="center"
          align="center"
          style={{
            backgroundColor: `${token.colorSuccess}20`,
            borderRadius: "8px",
            padding: "4px 8px",
            marginRight: "8px",
          }}
        >
          <CalendarOutlined style={{ color: token.colorSuccess }} />
        </Flex>
        <Text type="secondary">{formattedDate}</Text>
      </Flex>
    </Card>
  );
};

export default ScoreCard;
