export const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Fallback: Copying text command was successful");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Async: Copying to clipboard was successful!");
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const speakAloud = (text) => {
  const utterance = new SpeechSynthesisUtterance(text);

  // Set the voice
  const voices = window.speechSynthesis.getVoices();
  utterance.voice = voices.find((voice) => voice.name === "Google US English");

  window.speechSynthesis.speak(utterance);
};
