import React, { useState } from "react";
import { QuizSettingsDisplay, QuizDisplay } from "../../../components";
import { quizData } from "../../../utils";
import { quizTypes, bookData } from "../../../constants";

function Quiz() {
  // State for quiz settings and view toggle
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizType, setQuizType] = useState([quizTypes[0].value]);
  const [chapters, setChapters] = useState([bookData.book.chapters[0].title]);
  const [difficulty, setDifficulty] = useState("Easy");
  const [numQuestions, setNumQuestions] = useState(5);
  const [allQuestions, setAllQuestions] = useState(true);
  const [containsRedemptionQuestions, setContainsRedemptionQuestions] =
    useState(false);
  const [containsSkippedQuestions, setContainsSkippedQuestions] =
    useState(true);

  // Function to handle the start quiz action
  const startQuiz = () => {
    setQuizStarted(true); // Switch to quiz display view
  };

  // Function to handle the end quiz action
  const handleQuizEnd = () => {
    setQuizStarted(false); // Switch back to quiz settings view
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Conditionally render either QuizSettingsDisplay or QuizDisplay */}
      {!quizStarted ? (
        <QuizSettingsDisplay
          quizType={quizType}
          onQuizTypeChange={setQuizType}
          chapters={chapters}
          onChaptersChange={setChapters}
          difficulty={difficulty}
          onDifficultyChange={setDifficulty}
          numQuestions={numQuestions}
          onNumQuestionsChange={setNumQuestions}
          allQuestions={allQuestions}
          onAllQuestionsToggle={setAllQuestions}
          containsRedemptionQuestions={containsRedemptionQuestions}
          onRedemptionQuestionsToggle={setContainsRedemptionQuestions}
          containsSkippedQuestions={containsSkippedQuestions}
          onSkippedQuestionsToggle={setContainsSkippedQuestions}
          onStartQuiz={startQuiz}
        />
      ) : (
        <QuizDisplay
          quizType={quizType}
          difficulty={difficulty}
          numQuestions={numQuestions}
          allQuestions={allQuestions}
          quizData={quizData}
          onQuizEnd={handleQuizEnd}
        />
      )}
    </div>
  );
}

export default Quiz;
