import React from "react";
import { Flex } from "antd";
import styled from "styled-components";
import InfoBadge from "./info-badge";

const CardContainer = styled.div`
  padding: 2vh;
  border-radius: 16px;
  box-shadow: 0px 13px 49px 0px #0000000F;
  background-color: ${props => props.theme.token.colorWhite};
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1.5vh;
  }
`;

const ColumnContainer = styled(Flex)`
  gap: 1vh;
  width: 100%;
`;

const InfoCard = ({ columns }) => {
  return (
    <CardContainer>
      <Flex gap="1vh" style={{ width: "100%" }}>
        {columns.map((column, colIndex) => (
          <ColumnContainer vertical key={colIndex}>
            {column.map((badge, badgeIndex) => (
              <InfoBadge
                key={badgeIndex}
                text={badge.text}
                borderColor={badge.borderColor}
                rotation={badge.rotation}
              />
            ))}
          </ColumnContainer>
        ))}
      </Flex>
    </CardContainer>
  );
};

export default InfoCard; 