import React, { useState } from "react";
import { Typography, Button, Collapse, Flex, theme } from "antd";
import styled from "styled-components";
import { ASSETS } from "../constants";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const WorksText = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 8px;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -85px;
    width: 34px;
    height: 34px;
    background-image: url("images/two-stars.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 130%;
    height: 130%;
    background-image: url("images/works-circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
`;

const TabCard = styled.div`
  position: relative;
  background: ${(props) => props.theme.token.colorWhite};
  border-radius: 37px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;
    padding: 8px;
    border-radius: 16px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -70px;
    right: -16px;
    width: 32px;
    height: 32px;
    background-image: url("images/shine-three-lines.svg");
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    z-index: 1;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const TabButton = styled(Button)`
  padding: 20px;
  border-radius: 38px;
  border: 1px solid ${(props) => props.theme.token.borderColorGrey};
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  transition: none;

  @media (max-width: 768px) {
    width: calc(50% - 2px);
    flex: none;
    font-size: 11px;
    padding: 12px;
    border-radius: 24px;
  }

  &.active {
    background-color: ${(props) => props.theme.token.colorPrimary} !important;
    color: ${(props) => props.theme.token.colorWhite} !important;
  }

  &:not(.active) {
    background-color: ${(props) => props.theme.token.colorWhite};
    color: ${(props) => props.theme.token.colorBlack};

    &:hover {
      outline: 2px solid ${(props) => props.theme.token.colorPrimary};
      outline-offset: -2px;
    }
  }
`;

const ContentSection = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CollapseSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    border: none;
    background: ${(props) => props.theme.token.colorFooterCard};
    border-radius: 16px;
    transition: background-color 0.3s ease;

    .ant-collapse-item {
      border: none;
      background: transparent;
      transition: background-color 0.3s ease;
      border-radius: 16px;

      .ant-collapse-header {
        font-weight: 500;
        font-size: 16px;
      }

      .ant-collapse-content {
        border-top: none;
      }
    }

    .ant-collapse-item-active {
      background: ${(props) => props.theme.token.colorBlue};

      .ant-collapse-header {
        color: ${(props) => props.theme.token.colorWhite} !important;
        font-weight: 600;
        font-size: 18px;
      }

      .ant-collapse-content {
        background: ${(props) => props.theme.token.colorBlue};
        color: ${(props) => props.theme.token.colorWhite};
        border-top: none;
      }
    }
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

const AIWorksSection = () => {
  const { token } = useToken();
  const [activeTab, setActiveTab] = useState("talk");

  const tabs = [
    { key: "talk", label: "Talk to your textbooks" },
    { key: "practice", label: "Practice makes you Performer" },
    { key: "analytics", label: "Real time analytics" },
    { key: "language", label: "Practice a foreign language" },
  ];

  const tabContent = {
    talk: [
      {
        key: "1",
        label: "100% Accurate Answers",
        content:
          "Get precise responses to your questions with GradeMakers advanced AI technology",
      },
      {
        key: "2",
        label: "Conversation Starters",
        content:
          "Begin meaningful discussions about any topic in your textbook with the help of ice breaker questions.",
      },
      {
        key: "3",
        label: "Unlimited Q&As",
        content:
          "Ask as many questions as you need, across multiple chapters or the entire book",
      },
      {
        key: "4",
        label: "AI at its best",
        content:
          "Easy to understand explanation of fudamentals & difficult concepts with examples and diagrams.",
      },
    ],
    practice: [
      {
        key: "1",
        label: "Personalized Practice",
        content:
          "Get practice recommendations based on your preparation & performance.",
      },
      {
        key: "2",
        label: "Unlimited Practice tests",
        content: "Practice until you Master.",
      },
      {
        key: "3",
        label: "AI Evaluation",
        content: "Instant scoring and performance analysis done by AI.",
      },
      {
        key: "4",
        label: "Adaptive Difficulty",
        content:
          "Select wide range of settings like difficulty level, type of questions etc and many other interesting choices.",
      },
    ],
    analytics: [
      {
        key: "1",
        label: "Performance Metrics",
        content: "View detailed analysis of the progress you've made.",
      },
      {
        key: "2",
        label: "Strength Analysis",
        content:
          "Detailed insights on areas of improvement and areas of strength.",
      },
      {
        key: "3",
        label: "Improvement path",
        content:
          "Recommendations based preparation plan to strenghten the weaker areas.",
      },
      {
        key: "4",
        label: "Get Rewarded",
        content: "Unlock awards as you start performing.",
      },
    ],
    language: [
      {
        key: "1",
        label: "Practice a foreign language",
        content: "Choose from 100+ foriegn language options.",
      },
      {
        key: "2",
        label: "Interactive Conversations",
        content: "Practice speaking with AI-powered language avatars.",
      },
      {
        key: "3",
        label: "Pronunciation Guide",
        content: "Get real-time feedback on your pronunciation",
      },
      {
        key: "4",
        label: "Story narration",
        content:
          "Let AI narrate a story in the language of your choice followed by a Q&A.",
      },
    ],
  };

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 600,
            marginBottom: "16px",
            ...(window.innerWidth <= 768 && { fontSize: "36px" }),
          }}
        >
          AI <WorksText>Works</WorksText>
        </Title>
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 500,
            color: token.textColorGrey,
          }}
        >
          Stay ahead this session
        </Text>
      </HeaderSection>

      <TabCard theme={{ token }}>
        {tabs.map((tab, index) => (
          <TabButton
            key={tab.key}
            className={activeTab === tab.key ? "active" : ""}
            onClick={() => setActiveTab(tab.key)}
            theme={{ token }}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabCard>

      <ContentSection>
        <CollapseSection>
          {tabContent[activeTab].map((item) => (
            <StyledCollapse key={item.key} theme={{ token }}>
              <Panel header={item.label} key={item.key}>
                {item.content}
              </Panel>
            </StyledCollapse>
          ))}
        </CollapseSection>
        <ImageSection>
          <img
            src={ASSETS.aiWorksSectionGraphic}
            alt="Feature illustration"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </ImageSection>
      </ContentSection>
    </SectionContainer>
  );
};

export default AIWorksSection;
