const DashboardDrawerIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_344_963)">
        <path
          d="M10.5525 3.99002C12.195 4.50002 13.5 5.80503 14.01 7.44753C14.1525 7.92002 14.58 8.25002 15.0675 8.25002H15.0975C15.8475 8.25002 16.3875 7.53003 16.17 6.81752C15.4425 4.43252 13.56 2.55002 11.175 1.82252C10.47 1.60502 9.75 2.14502 9.75 2.89502V2.92502C9.75 3.42002 10.08 3.84752 10.5525 3.99002ZM14.01 10.5525C13.5 12.2025 12.195 13.5 10.5525 14.01C10.08 14.1525 9.75 14.58 9.75 15.0675V15.0975C9.75 15.8475 10.47 16.3875 11.1825 16.17C13.5675 15.4425 15.45 13.56 16.1775 11.175C16.395 10.4625 15.855 9.74252 15.1125 9.74252H15.075C14.58 9.75002 14.1525 10.08 14.01 10.5525ZM8.25 15.0825C8.25 14.58 7.9125 14.1525 7.4325 14.0025C5.3025 13.335 3.75 11.3475 3.75 9.00002C3.75 6.65252 5.3025 4.66502 7.4325 3.99752C7.9125 3.84752 8.25 3.42002 8.25 2.91752V2.91002C8.25 2.16002 7.5225 1.60502 6.8025 1.83002C3.735 2.76752 1.5 5.62502 1.5 9.00002C1.5 12.375 3.735 15.2325 6.8025 16.17C7.5225 16.395 8.25 15.84 8.25 15.0825Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_344_963">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardDrawerIcon;
