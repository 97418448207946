import React, { useState, useEffect } from "react";
import { Layout, Button, Space, Typography, theme } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { AppLogo } from "../components";
import MobileDrawer from "./mobile-drawer";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const StickyHeader = styled(Layout.Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 80px;
  background-color: ${(props) => props.theme.token.colorBgBase};
  border-bottom: 1px solid ${(props) => props.theme.token.colorBgLayout};
  transition: box-shadow 0.3s ease;
  box-shadow: ${(props) =>
    props.scrolled ? "0 2px 8px rgba(0, 0, 0, 0.06)" : "none"};

  @media (min-width: 768px) {
    padding: 0 120px;
  }
`;

const DesktopButtons = styled(Space)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const MobileMenuButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const LandingHeader = ({ currentPage }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <StickyHeader theme={{ token }} scrolled={isScrolled}>
        <AppLogo />

        <DesktopButtons size={10}>
          <Button
            onClick={() => navigate("/signin")}
            type="primary"
            style={{
              backgroundColor: token.colorBgLayout,
              color: token.colorBlack,
              borderRadius: "12px",
              padding: "12px 16px",
              fontWeight: "400",
              fontSize: "12px",
              width: "132px",
              height: "48px",
              boxShadow: "none",
            }}
          >
            Login
          </Button>
          <Button
            onClick={() => navigate("/signup")}
            type="primary"
            style={{
              borderRadius: "12px",
              backgroundColor: token.colorBlue,
              color: token.colorWhite,
              padding: "12px 16px",
              fontWeight: "400",
              fontSize: "12px",
              width: "132px",
              height: "48px",
              boxShadow: "none",
            }}
          >
            Signup
          </Button>
        </DesktopButtons>

        <MobileMenuButton
          type="text"
          icon={<MenuOutlined style={{ fontSize: "24px" }} />}
          onClick={() => setDrawerVisible(true)}
        />
      </StickyHeader>

      <MobileDrawer visible={drawerVisible} onClose={() => setDrawerVisible(false)} />
      <div style={{ height: "80px" }} />
    </>
  );
};

export default LandingHeader;
