import React, { useState, useEffect, useRef } from "react";
import { Flex, Button, Form, Input, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import { AppLogo } from "../../../components";

const { Title, Text } = Typography;

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [step, setStep] = useState("email"); // "email" or "otp" step
  const [timer, setTimer] = useState(120); // Countdown timer starting from 120 seconds
  const [timerActive, setTimerActive] = useState(false); // To control the timer state
  const otpInputRef = useRef(null); // Reference to the OTP input field

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerActive(false); // Stop the timer when it reaches 0
    }
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [timerActive, timer]);

  useEffect(() => {
    if (step === "otp") {
      otpInputRef.current?.focus(); // Focus OTP input field when we switch to OTP step
    }
  }, [step]);

  const onFinishEmail = (values) => {
    console.log("Received email values:", values);
    setStep("otp"); // Move to the OTP step
    setTimer(10); // Reset the timer to 2 minutes
    setTimerActive(true); // Start the countdown
  };

  const onFinishOTP = (values) => {
    console.log("Received OTP values:", values);
    // Handle OTP verification logic here
  };

  const handleResendOTP = () => {
    setTimer(10); // Reset the timer to 2 minutes
    setTimerActive(true); // Start the countdown
    setStep("otp"); // Ensure OTP step is displayed
    // Reset form validation state if any errors are present
    form.resetFields(["otp"]); // Reset OTP field to clear any validation error
  };

  const handleGoBack = () => {
    form.resetFields();
    setStep("email"); // Reset to email step
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        width: "100vw",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Flex
        vertical
        align="center"
        style={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <AppLogo height="70px" />
        </Flex>

        <Flex
          vertical
          align="center"
          style={{
            backgroundColor: "#fff",
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
            padding: "40px",
            boxShadow: "0 8px 8px rgba(0,0,0,0.1)",
          }}
        >
          {step === "email" ? (
            <>
              <Title level={1} style={{ margin: 0 }}>
                Forgot Password?
              </Title>
              <Text style={{ marginTop: "10px", textAlign: "center" }}>
                Enter your email to receive a password reset link
              </Text>
              <Divider />

              <Form
                form={form}
                name="reset_password"
                onFinish={onFinishEmail}
                layout="vertical"
                style={{ width: "100%", maxWidth: "300px" }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Flex
                  justify="flex-start"
                  style={{ marginTop: "-20px", marginBottom: "20px" }}
                >
                  <Link to="/forgot-email">
                    <Text style={{ fontSize: "14px", color: "#5952d7" }} strong>
                      Forgot Email?
                    </Text>
                  </Link>
                </Flex>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", backgroundColor: "#5952d7" }}
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <Title level={1} style={{ margin: 0 }}>
                Enter OTP
              </Title>
              <Text style={{ marginTop: "10px", textAlign: "center" }}>
                Please enter the 5-digit OTP sent to your email
              </Text>
              <Divider />

              <Form
                form={form}
                name="otp_form"
                onFinish={onFinishOTP}
                layout="vertical"
                style={{ width: "100%", maxWidth: "300px" }}
              >
                <Form.Item
                  name="otp"
                  rules={[
                    { required: true, message: "Please input your OTP!" },
                    {
                      len: 5,
                      message: "OTP must be 5 digits!",
                    },
                  ]}
                >
                  <Input.OTP
                    ref={otpInputRef} // Assign the ref to the OTP input
                    length={5}
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      height: "auto", // Set height for square shape
                      textAlign: "center",
                      fontSize: "20px",
                      borderRadius: "8px",
                      padding: "0", // Ensures square shape
                    }}
                    formatter={(str) => str.toUpperCase()}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", backgroundColor: "#5952d7" }}
                  >
                    Verify OTP
                  </Button>
                </Form.Item>
              </Form>

              <Flex justify="center" style={{ marginTop: "10px" }}>
                {timer > 0 ? (
                  <Text style={{ fontSize: "14px", color: "#5A5A5A" }}>
                    Resend code in {formatTime(timer)}
                  </Text>
                ) : (
                  <Text style={{ fontSize: "14px", color: "#5A5A5A" }}>
                    Didn't get a code?{" "}
                    <Link onClick={handleResendOTP}>
                      <Text style={{ color: "#5952d7" }} strong>
                        Click to resend.
                      </Text>
                    </Link>
                  </Text>
                )}
              </Flex>

              {/* "Entered a wrong email?" text and "Go back" link */}
              <Flex justify="center" style={{ marginTop: "20px" }}>
                <Text style={{ fontSize: "14px", color: "#5952d7" }}>
                  Entered a wrong email?{" "}
                  <Link onClick={handleGoBack}>
                    <Text style={{ color: "#5952d7" }} strong>
                      Go back
                    </Text>
                  </Link>
                </Text>
              </Flex>
            </>
          )}

          {/* Retain "Back to Sign In" for Forgot Password screen */}
          {step === "email" && (
            <Flex justify="center" style={{ marginTop: "20px" }}>
              <Text style={{ fontSize: "14px", color: "#5952d7" }}>
                Back to{" "}
                <Link to="/signin">
                  <Text style={{ color: "#5952d7" }} strong>
                    Sign In
                  </Text>
                </Link>
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
