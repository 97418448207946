import { Row, Col, Typography, Flex, Progress, theme } from "antd";
import {
  CloseOutlined,
  PauseOutlined,
  ReloadOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { QuizInfoCard, QuizSettingsIcon } from "../../components";

const { useToken } = theme;
const { Title, Text } = Typography;

function QuizInfoCardsSection({
  difficulty,
  timeLeft,
  questionsAnswered,
  totalQuestions,
  onPauseResume,
  onRestart,
  onStop,
  isPaused,
}) {
  const { token } = useToken();
  const progressPercentage = Math.round(
    (questionsAnswered / totalQuestions) * 100
  );

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 8 }}
        xl={{ span: 8 }}
      >
        <QuizInfoCard
          title={"Progress"}
          showDifficulty={true}
          difficulty={difficulty}
        >
          <Text>{`${questionsAnswered}/${totalQuestions}`}</Text>
          <Progress
            percent={progressPercentage}
            showInfo={false}
            size="small"
          />
        </QuizInfoCard>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 8 }}
        xl={{ span: 8 }}
      >
        <QuizInfoCard title={"Timer"}>
          <Title level={3} style={{ margin: 0 }}>
            {timeLeft}
          </Title>
        </QuizInfoCard>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 8 }}
        xl={{ span: 8 }}
      >
        <QuizInfoCard title={"Settings"}>
          <Flex justify="space-evenly">
            <QuizSettingsIcon
              icon={isPaused ? <PlayCircleOutlined /> : <PauseOutlined />}
              tooltipTitle={isPaused ? "Resume Quiz" : "Pause Quiz"}
              onClick={onPauseResume}
              border={token.border}
            />
            <QuizSettingsIcon
              icon={<ReloadOutlined />}
              tooltipTitle={"Restart Quiz"}
              onClick={onRestart}
              border={token.border}
            />
            <QuizSettingsIcon
              icon={<CloseOutlined />}
              tooltipTitle={"Stop Quiz"}
              onClick={onStop}
              border={token.border}
            />
          </Flex>
        </QuizInfoCard>
      </Col>
    </Row>
  );
}

export default QuizInfoCardsSection;
