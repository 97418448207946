import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Typography, Button, Space, Flex, theme } from "antd";
import styled from "styled-components";
import { AppLogo } from "../components";

const { Text } = Typography;
const { useToken } = theme;

const FooterContainer = styled.div`
  padding: 60px 120px 40px;
  background-color: ${(props) => props.theme.token.colorWhite};
  
  @media (max-width: 768px) {
    padding: 40px 20px;
    text-align: center;
  }
`;

const SocialCard = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.token.colorFooterCard};
  border: 1px solid ${(props) => props.theme.token.colorFooterBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FooterLink = styled.a`
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.token.colorPrimary};
  }
`;

const LogoContainer = styled.div`
  padding: 0;
  img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const useResponsive = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};

const LandingFooter = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const footerLinks = {
    column1: {
      heading: "Navigation",
      links: [
        { text: "Features", path: "/features" },
        { text: "Testimonials", path: "/testimonials" },
        { text: "FAQs", path: "/faqs" },
        { text: "Contact Us", path: "/contact" },
      ],
    },
    column2: {
      heading: "Subjects",
      links: [
        { text: "Mathematics", path: "/maths" },
        { text: "Biology", path: "/biology" },
        { text: "Physics", path: "/physics" },
        { text: "Explore More", path: "/explore" },
      ],
    },
    column3: {
      heading: "Other Links",
      links: [
        { text: "Privacy Policy", path: "/privacypolicy" },
        { text: "Terms & Conditions", path: "/termsandconditions" },
        { text: "Privacy Center", path: "/privacycenter" },
      ],
    },
  };

  return (
    <FooterContainer theme={{ token }}>
      <Flex 
        vertical={window.innerWidth <= 768}
        justify="space-between" 
        style={{ marginBottom: "40px" }}
      >
        {/* Left Section */}
        <Flex 
          vertical 
          gap="large"
          style={{ 
            width: window.innerWidth <= 768 ? '100%' : 'auto',
            alignItems: window.innerWidth <= 768 ? 'center' : 'flex-start' 
          }}
        >
          <LogoContainer>
            <AppLogo />
          </LogoContainer>

          <Flex gap="small" style={{ width: 'auto' }}>
            <Button
              type="primary"
              onClick={() => navigate('/signup')}
              style={{
                width: "207px",
                height: "48px",
                padding: "12px 16px",
                fontSize: "12px",
                fontWeight: 400,
                border: "none",
              }}
            >
              Signup for Free
            </Button>
          </Flex>

          {/* Social Media Links will be moved to bottom for mobile */}
          {window.innerWidth > 768 && (
            <Flex gap="10px">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialCard theme={{ token }}>
                  <img
                    src="images/facebook-icon.svg"
                    alt="Facebook"
                    style={{
                      height: "20px",
                      width: "auto",
                      filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                    }}
                  />
                </SocialCard>
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialCard theme={{ token }}>
                  <img
                    src="images/linkedin-icon.svg"
                    alt="LinkedIn"
                    style={{
                      height: "24px",
                      width: "24px",
                      filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                    }}
                  />
                </SocialCard>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialCard theme={{ token }}>
                  <img
                    src="images/instagram-icon.svg"
                    alt="Instagram"
                    style={{
                      height: "24px",
                      width: "24px",
                      filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                    }}
                  />
                </SocialCard>
              </a>
            </Flex>
          )}
        </Flex>

        {/* Right Section */}
        <Flex 
          style={{ 
            width: window.innerWidth <= 768 ? '100%' : '50%',
            marginTop: window.innerWidth <= 768 ? '40px' : 0 
          }} 
          vertical={window.innerWidth <= 768}
          justify="space-between"
          gap={window.innerWidth <= 768 ? "40px" : "0"}
        >
          {Object.values(footerLinks).map((column, index) => (
            <Flex 
              vertical 
              gap="20px" 
              key={index}
              align={isMobile ? "center" : "flex-start"}
            >
              <Text strong>{column.heading}</Text>
              {column.links.map((link, linkIndex) => (
                <FooterLink
                  key={linkIndex}
                  href={link.path}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(link.path);
                  }}
                  theme={{ token }}
                >
                  {link.text}
                </FooterLink>
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>

      {/* Social Media Links for mobile */}
      {window.innerWidth <= 768 && (
        <Flex gap="10px" justify="center" style={{ marginBottom: "40px" }}>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialCard theme={{ token }}>
              <img
                src="images/facebook-icon.svg"
                alt="Facebook"
                style={{
                  height: "20px",
                  width: "auto",
                  filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                }}
              />
            </SocialCard>
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialCard theme={{ token }}>
              <img
                src="images/linkedin-icon.svg"
                alt="LinkedIn"
                style={{
                  height: "24px",
                  width: "24px",
                  filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                }}
              />
            </SocialCard>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialCard theme={{ token }}>
              <img
                src="images/instagram-icon.svg"
                alt="Instagram"
                style={{
                  height: "24px",
                  width: "24px",
                  filter: `brightness(0) saturate(100%) ${token.colorPrimary}`,
                }}
              />
            </SocialCard>
          </a>
        </Flex>
      )}

      {/* Copyright Text */}
      <Text
        style={{
          fontSize: "16px",
          fontWeight: 400,
          display: "block",
          textAlign: "center",
        }}
      >
        Copyrights © 2025
      </Text>
    </FooterContainer>
  );
};

export default LandingFooter;
