import React, { useState } from "react";
import { Card, Flex, Button, theme } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PDFViewer } from "../../../components";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const { useToken } = theme;

function PDFCard({ viewerWidth, viewerHeight }) {
  const { token } = useToken();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePrev = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  return (
    <Card
      style={{
        width: `${viewerWidth + 120}px`,
        height: `${viewerHeight}px`,
        marginLeft: "12px",
        borderRadius: "16px",
        border: token.border,
        backgroundColor: token.colorWhite,
      }}
    >
      <Flex align="center" justify="middle">
        <Button
          shape="circle"
          type="primary"
          icon={<LeftOutlined />}
          onClick={handlePrev}
          disabled={pageNumber === 1}
        />

        <div style={{ width: "100%", overflow: "hidden" }}>
          <PDFViewer
            pageNumber={pageNumber}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            width={viewerWidth}
          />
        </div>

        <Button
          shape="circle"
          type="primary"
          icon={<RightOutlined />}
          onClick={handleNext}
          disabled={pageNumber === numPages}
        />
      </Flex>
    </Card>
  );
}

export default PDFCard;
