import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Layout,
  Row,
  Col,
  message,
} from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ASSETS } from "../../../constants";
import { AppLogo } from "../../../components";
import { registerUser } from "../../../services";
import { motion } from "framer-motion";
import styled from "styled-components";

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color when unchecked
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color on hover when unchecked
  }
`;

const { Content } = Layout;

const FormStyles = styled.div`
  .custom-form-item {
    .ant-form-item-explain-error {
      text-align: left;
      padding-left: 4px;
    }
  }
`;

export default function Signup() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // Check if the user is an admin
  const isAdmin = location.pathname.match("signup/admin");

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Remove any existing +91 prefix and format the phone number
      const phoneNumber = values.phone.replace(/^\+91/, "").trim();
      const formattedPhone = `+91${phoneNumber}`;

      const userData = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: formattedPhone,
        email: values.email,
        password: values.password,
        confirm_password: values.confirmPassword,
        role: isAdmin ? "admin" : "student",
      };

      const response = await registerUser(userData);
      console.log(response);

      if (response.error) {
        throw new Error(response.error);
      }

      message.success("Registration successful! Please log in.");
      navigate("/signin");
    } catch (error) {
      message.error(
        "Registration failed: " + (error.message || "Unknown error")
      );
    } finally {
      setLoading(false);
    }
  };

  const formRules = {
    firstName: [
      { required: true, message: "Please input your first name!" },
      { min: 2, message: "First name must be at least 2 characters!" },
    ],
    lastName: [
      { required: true, message: "Please input your last name!" },
      { min: 2, message: "Last name must be at least 2 characters!" },
    ],
    phone: [
      { required: true, message: "Please input your phone number!" },
      {
        pattern: /^(\+91|0)?[1-9]\d{9}$/,
        message: "Please enter a valid phone number!",
      },
    ],
    email: [
      { required: true, message: "Please input your email!" },
      { type: "email", message: "Please enter a valid email address!" },
    ],
    password: [
      { required: true, message: "Please input your password!" },
      { min: 8, message: "Password must be at least 8 characters!" },
    ],
    confirmPassword: [
      { required: true, message: "Please confirm your password!" },
    ],
  };

  const leftSectionRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (leftSectionRef.current) {
        setContainerHeight(leftSectionRef.current.offsetHeight);
      }
    };
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <Layout style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <Row gutter={0} style={{ height: "100%" }}>
        {/* Left Section (Only visible on larger screens) */}
        <Col xs={0} sm={0} md={12} lg={12} xl={12}>
          <div
            ref={leftSectionRef}
            style={{
              backgroundColor: "#110D1C",
              borderRadius: "36px",
              padding: "36px",
              position: "relative",
              height: "100%",
              clipPath: "inset(0% 0% 0% 0%)",
              overflow: "hidden",
            }}
          >
            {/* Animating Text */}
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.5,
                duration: 1.2,
                type: "spring",
                stiffness: 100,
              }}
              style={{
                fontSize: "36px",
                fontWeight: "300",
                color: "#fff",
                lineHeight: "1.2",
                textAlign: "left",
              }}
            >
              Ready to dive into learning magic? <br />
              Sign up and let's make studying <br />
              seriously fun!
            </motion.div>

            {/* Falling SVGs */}
            <motion.div
              initial={{ y: "-100vh" }}
              animate={{ y: containerHeight * 0.75 }}
              transition={{
                delay: 1,
                duration: 1.5,
                type: "spring",
                stiffness: 100,
              }}
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                zIndex: 1,
              }}
            >
              {/* CS Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.2,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.csBookCard}
                  alt="CS"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>

              {/* LA Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.4,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.laBookCard}
                  alt="LA"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>

              {/* PHY Image */}
              <motion.div
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                transition={{
                  delay: 1.6,
                  duration: 1.5,
                  type: "spring",
                  stiffness: 100,
                }}
                style={{
                  width: "33%",
                  height: "auto",
                  zIndex: 1,
                }}
              >
                <img
                  src={ASSETS.phyBookCard}
                  alt="PHY"
                  style={{
                    width: "150%",
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </motion.div>
            </motion.div>
          </div>
        </Col>

        {/* Right Section */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "36px",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {/* Logo Section */}
            <div
              style={{
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              <AppLogo height="70px" />
            </div>

            {/* Middle Section */}
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <img
                src={ASSETS.waveHand}
                alt="Waving Emoji"
                style={{ height: 50, marginBottom: 10 }}
              />
              <div
                style={{ fontSize: 32, fontWeight: "400", marginBottom: 10 }}
              >
                Prepare. Practice. Perform
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  color: "#6B7B82",
                  marginBottom: 30,
                }}
              >
                Fill in the fields to register a new account.
              </div>

              <FormStyles>
                <Form
                  form={form}
                  name="signup"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ maxWidth: 350, margin: "0 auto" }}
                  requiredMark={false}
                >
                  {/* First Name */}
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={formRules.firstName}
                    style={{ marginBottom: 12 }}
                    validateStatus="error"
                    className="custom-form-item"
                  >
                    <Input
                      placeholder="Enter your first name"
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 16px",
                        borderRadius: "8px",
                      }}
                    />
                  </Form.Item>

                  {/* Last Name */}
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={formRules.lastName}
                    style={{ marginBottom: 12 }}
                    validateStatus="error"
                    className="custom-form-item"
                  >
                    <Input
                      placeholder="Enter your last name"
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 16px",
                        borderRadius: "8px",
                      }}
                    />
                  </Form.Item>

                  {/* Phone */}
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={formRules.phone}
                    style={{ marginBottom: 12 }}
                    className="custom-form-item"
                  >
                    <Input
                      addonBefore="+91"
                      placeholder="Enter Phone"
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 0",
                        borderRadius: "8px",
                      }}
                      className="custom-input"
                    />
                  </Form.Item>

                  {/* Email */}
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={formRules.email}
                    style={{ marginBottom: 12 }}
                    validateStatus="error"
                    className="custom-form-item"
                  >
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 16px",
                        borderRadius: "8px",
                      }}
                    />
                  </Form.Item>

                  {/* Password */}
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={formRules.password}
                    style={{ marginBottom: 12 }}
                    validateStatus="error"
                    className="custom-form-item"
                  >
                    <Input.Password
                      placeholder="Enter your password"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 16px",
                        borderRadius: "8px",
                      }}
                    />
                  </Form.Item>

                  {/* Confirm Password */}
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    validateStatus="error"
                    className="custom-form-item"
                    rules={[
                      ...formRules.confirmPassword,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match!")
                          );
                        },
                      }),
                    ]}
                    style={{ marginBottom: 12 }}
                  >
                    <Input.Password
                      placeholder="Confirm your password"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      style={{
                        backgroundColor: "#F5F7FA",
                        border: "none",
                        padding: "12px 16px",
                        borderRadius: "8px",
                      }}
                    />
                  </Form.Item>

                  {/* Terms and Conditions */}
                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    validateStatus="error"
                    className="custom-form-item"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "Please accept the terms and conditions"
                                )
                              ),
                      },
                    ]}
                    style={{ marginBottom: 20 }}
                  >
                    <CustomCheckbox
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      I accept the{" "}
                      <Link
                        to="/terms"
                        style={{ fontWeight: "bold", color: "#000" }}
                      >
                        terms and conditions
                      </Link>
                    </CustomCheckbox>
                  </Form.Item>

                  {/* Sign-Up Button */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "#FF6C42",
                      borderColor: "#FF6C42",
                      height: 48,
                      borderRadius: 8,
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                    loading={loading}
                  >
                    Sign Up
                  </Button>

                  {/* Continue with Google Button */}
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      color: "#000",
                      border: "1px solid #ccc",
                      height: 48,
                      borderRadius: 8,
                      fontSize: 12,
                      fontWeight: 400,
                      marginTop: 10,
                    }}
                  >
                    <img
                      src={ASSETS.googleLogo}
                      alt="Google"
                      style={{ marginRight: 8, height: 20 }}
                    />
                    Continue With Google
                  </Button>
                </Form>
              </FormStyles>
            </div>

            {/* Sign-in Link */}
            <div style={{ textAlign: "center" }}>
              <Typography.Text>
                Already have an account?{" "}
                <Link to="/signin">
                  <Typography.Text strong style={{ color: "#000" }}>
                    Sign In
                  </Typography.Text>
                </Link>
              </Typography.Text>
            </div>
          </Content>
        </Col>
      </Row>
    </Layout>
  );
}
