import React from "react";
import { Flex, Typography, Card, theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const ImprovementAreasCard = ({ title, color, items }) => {
  const { token } = useToken();

  return (
    <Flex
      vertical
      style={{
        flex: "0 0 49.2%",
        maxWidth: "49.2%",
        padding: "24px",
        backgroundColor: token.colorWhite,
        marginTop: "16px",
        marginRight: "16px",
        borderRadius: "16px",
      }}
    >
      <Text strong style={{ fontSize: "16px", marginBottom: "16px" }}>
        {title}
      </Text>
      <Flex wrap="wrap" gap={16}>
        {items.map((item, index) => (
          <Card
            key={index}
            style={{
              backgroundColor: token.colorWhite,
              borderRadius: "16px",
              boxShadow: "0px 12px 34.8px 0px #0000000D",
              border: "none",
              flex: "0 0 calc(50% - 8px)",
            }}
            bodyStyle={{ padding: "16px" }}
          >
            <Flex align="start" vertical>
              <Flex
                justify="center"
                align="center"
                style={{
                  backgroundColor: color,
                  borderRadius: "8px",
                  width: "40px",
                  height: "40px",
                  marginRight: "12px",
                }}
              >
                {React.cloneElement(item.icon, {
                  style: { color: token.colorWhite, fontSize: "20px" },
                })}
              </Flex>
              <Text
                style={{ color: color, marginTop: "16px", fontWeight: 600 }}
              >
                {item.text}
              </Text>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export default ImprovementAreasCard;
