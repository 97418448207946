import React, { useState, useEffect } from "react";
import { Typography, Flex, theme } from "antd";
import styled from "styled-components";
import InfoCard from "./info-card";
import GraphicCard from "./graphic-card";
import ArrowTextCard from "./arrow-text-card";
import { ASSETS } from "../../constants";

const { Title } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
`;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
};

const CoveredSection = () => {
  const { token } = useToken();
  const isMobile = useIsMobile();

  const boardsData = [
    { text: "IB", borderColor: token.borderColorPurple, rotation: 0 },
    { text: "CBSE", borderColor: token.borderColorOrange, rotation: -2 },
    { text: "ICSE", borderColor: token.borderColorGreen, rotation: 0 },
  ];

  const gradesData = [
    [
      { text: "Grade 5", borderColor: token.borderColorPurple, rotation: -4 },
      { text: "Grade 7", borderColor: token.borderColorLime, rotation: 3 },
      { text: "Grade 9", borderColor: token.borderColorPurple, rotation: 1 },
    ],
    [
      { text: "Grade 6", borderColor: token.borderColorLime, rotation: 4 },
      { text: "Grade 8", borderColor: token.borderColorOrange, rotation: -3 },
      { text: "Grade 10", borderColor: token.borderColorOrange, rotation: -1 },
    ],
  ];

  const subjectsData = [
    [
      { text: "Hindi", borderColor: token.borderColorPurple, rotation: 3 },
      { text: "EVS", borderColor: token.borderColorLime, rotation: -2 },
      { text: "History", borderColor: token.borderColorPurple, rotation: -2.5 },
      { text: "Geography", borderColor: token.borderColorOrange, rotation: 0 },
    ],
    [
      { text: "English", borderColor: token.borderColorLime, rotation: -3.5 },
      { text: "Maths", borderColor: token.borderColorOrange, rotation: 3 },
      { text: "Computer", borderColor: token.borderColorOrange, rotation: 2.5 },
      { text: "and more", borderColor: token.borderColorLime, rotation: 3 },
    ],
  ];

  const languagesData = [
    [
      { text: "Hindi", borderColor: token.borderColorPurple, rotation: 0 },
      { text: "English", borderColor: token.borderColorOrange, rotation: -3 },
    ],
  ];

  const arrowsData = [
    {
      text: "Exam Boards",
      isMirrored: true,
      isFlipped: false,
      rotation: 20,
      arrowMargin: 30,
    },
    {
      text: "Grades",
      isMirrored: false,
      isFlipped: false,
      rotation: 20,
      arrowMargin: 30,
    },
    {
      text: "Subjects",
      isMirrored: true,
      isFlipped: true,
      rotation: 0,
      arrowMargin: 0,
    },
    {
      text: "Languages",
      isMirrored: false,
      isFlipped: false,
      rotation: 0,
      arrowMargin: 0,
    },
  ];

  const mobileArrowsData = [
    {
      ...arrowsData[0],
      rotation: -20,
      isFlipped: true,
      arrowMargin: 0,
    },
    {
      ...arrowsData[1],
      rotation: 10,
      isFlipped: true,
      arrowMargin: -40,
    },
    {
      ...arrowsData[2],
      rotation: 0,
      isFlipped: false,
    },
    {
      ...arrowsData[3],
      rotation: 0,
      isFlipped: false,
    },
  ];

  return (
    <SectionContainer>
      <HeaderSection>
        <img
          src={ASSETS.coveredTextLeftGraphic}
          alt="Left Graphic"
          style={{ 
            position: "absolute",
            left: isMobile ? "-10px" : "5%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: -1,
            height: "60px",
            width: "auto",
          }}
        />
        <Title
          style={{
            fontSize: isMobile ? "32px" : "48px",
            fontWeight: 600,
            lineHeight: isMobile ? "40px" : "58px",
            textAlign: "center",
            marginBottom: "16px",
            width: "100%",
            maxWidth: isMobile ? "100%" : "800px",
          }}
        >
          We've got you covered
        </Title>
        <img
          src="images/covered-zigzag-graphic.svg"
          alt="Zigzag"
          style={{ 
            height: "37px",
            position: "absolute",
            bottom: "-40px",
            zIndex: -1
          }}
        />
      </HeaderSection>

      {isMobile ? (
        <Flex vertical gap={16}>
          {/* First Row */}
          <div style={{ marginBottom: '16px', alignSelf: 'flex-start' }}>
            <ArrowTextCard
              text={mobileArrowsData[0].text}
              isMirrored={mobileArrowsData[0].isMirrored}
              isFlipped={mobileArrowsData[0].isFlipped}
              rotation={mobileArrowsData[0].rotation}
              arrowMargin={mobileArrowsData[0].arrowMargin}
            />
          </div>
          <Flex gap={16} align="stretch">
            <div style={{ flex: 0.5 }}>
              <InfoCard columns={[boardsData]} />
            </div>
            <div style={{ flex: 0.5, paddingRight: '60px' }}>
              <GraphicCard
                imageUrl="images/covered-graphic-2.png"
                height="100%"
              />
            </div>
          </Flex>

          {/* Second Row */}
          <Flex gap={16} style={{ position: 'relative' }}>
            <div style={{ flex: 0.6 }}>
              <GraphicCard
                imageUrl="images/covered-graphic-1.png"
                height="100%"
                style={{ height: '100%' }}
              />
            </div>
            <div style={{ flex: 0.4, position: 'relative' }}>
              <div 
                style={{ 
                  position: 'absolute', 
                  top: '-120px', 
                  right: '-50px', 
                  zIndex: 2 
                }}
              >
                <ArrowTextCard
                  text={mobileArrowsData[1].text}
                  isMirrored={mobileArrowsData[1].isMirrored}
                  isFlipped={mobileArrowsData[1].isFlipped}
                  rotation={mobileArrowsData[1].rotation}
                  arrowMargin={mobileArrowsData[1].arrowMargin}
                />
              </div>
              <InfoCard columns={gradesData} />
            </div>
          </Flex>

          {/* Third Row */}
          <Flex gap={16}>
            <div style={{ flex: 0.4 }}>
              <InfoCard columns={languagesData} />
              <ArrowTextCard
                text={mobileArrowsData[3].text}
                isMirrored={mobileArrowsData[3].isMirrored}
                isFlipped={mobileArrowsData[3].isFlipped}
                rotation={mobileArrowsData[3].rotation}
              />
            </div>
            <div style={{ flex: 0.6 }}>
              <GraphicCard
                imageUrl="images/covered-graphic-3.png"
                height="30vh"
              />
            </div>
          </Flex>

          {/* Fourth Row */}
          <div style={{ width: "100%" }}>
            <InfoCard columns={subjectsData} />
            <ArrowTextCard
              text={mobileArrowsData[2].text}
              isMirrored={mobileArrowsData[2].isMirrored}
              isFlipped={mobileArrowsData[2].isFlipped}
              rotation={mobileArrowsData[2].rotation}
              arrowMargin={mobileArrowsData[2].arrowMargin}
            />
          </div>
        </Flex>
      ) : (
        // Existing web view layout
        <Flex gap={16}>
          {/* First Column - Slightly wider natural width */}
          <Flex
            vertical
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              flexShrink: 0,
              flexBasis: "18%",
              minWidth: "fit-content",
            }}
          >
            <InfoCard columns={[boardsData]} />
            <ArrowTextCard
              text={arrowsData[0].text}
              isMirrored={arrowsData[0].isMirrored}
              isFlipped={arrowsData[0].isFlipped}
              rotation={arrowsData[0].rotation}
              arrowMargin={arrowsData[0].arrowMargin}
            />
          </Flex>

          {/* Second Column - Takes up remaining space */}
          <Flex vertical gap={16} style={{ flex: "1 1 auto", minWidth: 0 }}>
            <Flex gap={16} style={{ alignItems: "flex-end", height: "100%" }}>
              <div style={{ flex: 0.6 }}>
                <GraphicCard
                  imageUrl="images/covered-graphic-1.png"
                  height="30vh"
                />
              </div>
              <div style={{ flex: 0.4 }}>
                <GraphicCard
                  imageUrl="images/covered-graphic-2.png"
                  height="25vh"
                />
              </div>
            </Flex>
            <Flex gap={16}>
              <div style={{ flex: 0.4 }}>
                <InfoCard columns={gradesData} />
                <ArrowTextCard
                  text={arrowsData[1].text}
                  isMirrored={arrowsData[1].isMirrored}
                  isFlipped={arrowsData[1].isFlipped}
                  rotation={arrowsData[1].rotation}
                  arrowMargin={arrowsData[1].arrowMargin}
                />
              </div>
              <div style={{ flex: 0.6 }}>
                <GraphicCard
                  imageUrl="images/covered-graphic-3.png"
                  height="30vh"
                />
              </div>
            </Flex>
          </Flex>

          {/* Third Column - Natural width */}
          <Flex
            vertical
            gap={16}
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              flexShrink: 0,
              maxWidth: "fit-content",
            }}
          >
            <ArrowTextCard
              text={arrowsData[2].text}
              isMirrored={arrowsData[2].isMirrored}
              isFlipped={arrowsData[2].isFlipped}
              rotation={arrowsData[2].rotation}
              arrowMargin={arrowsData[2].arrowMargin}
            />
            <InfoCard columns={subjectsData} />
            <div style={{ width: "80%" }}>
              <InfoCard columns={languagesData} />
              <ArrowTextCard
                text={arrowsData[3].text}
                isMirrored={arrowsData[3].isMirrored}
                isFlipped={arrowsData[3].isFlipped}
                rotation={arrowsData[3].rotation}
              />
            </div>
          </Flex>
        </Flex>
      )}
    </SectionContainer>
  );
};

export default CoveredSection;
