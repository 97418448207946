import React from "react";
import { Flex, theme } from "antd";
import styled from "styled-components";

const { useToken } = theme;

const ItemContainer = styled(Flex)`
  gap: 16px;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.token.colorText};
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.token.textColorGrey};
  width: 70%;
  line-height: 1.5;
`;

const Avatar = styled.img`
  height: 33px;
  width: 33px;
`;

const ListItem = ({ title, description, iconColor }) => {
  const getAvatarSrc = () => {
    switch(iconColor) {
      case 'textColorGrey':
        return 'images/grey-list-avatar.svg';
      case 'borderColorLime':
        return 'images/lime-list-avatar.svg';
      default:
        return 'images/green-list-avatar.svg';
    }
  };

  return (
    <ItemContainer>
      <Avatar 
        src={getAvatarSrc()} 
        alt="List Item"
      />
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </ItemContainer>
  );
};

export default ListItem; 