import React from "react";
import { Menu, Radio } from "antd";
import { BookFilled } from "@ant-design/icons";
import styled from "styled-components";

const CustomRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #FF6C42 !important;
    background-color: #FF6C42 !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #FF6C42 !important;
    border-color: #FF6C42 !important;
  }
  
  .ant-radio-inner {
    border-color: #FF6C42 !important;
    background-color: white !important;
  }

  .ant-radio-inner:after {
    background-color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
  }
`;

const CustomMenuItem = styled(Menu.Item)`
  background-color: transparent !important;
  background-color::hover: transparent !important;
  color: black !important;
  color::hover: black !important;
  border-bottom: 1px solid #F5F7FA; /* Default border for all items */
  border-radius: 0;
  border-right: none;
  padding: 0;

  &:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
`;

function BooksMenu({ onBookSelect, selectedBookId }) {
  const booksList = JSON.parse(localStorage.getItem("booksList"));

  const handleBookSelect = (e) => {
    const selectedBookId = parseInt(e.target.value, 10);
    const selectedBook = booksList.ebooks.find((book) => book.id === selectedBookId);
    onBookSelect(selectedBook);
  };

  return (
    <Menu mode="inline" style={{border: "none"}}>
      {booksList.ebooks.map((book) => (
        <CustomMenuItem key={`book-${book.id}`}>
          <CustomRadio
            checked={selectedBookId === book.id}
            onChange={handleBookSelect}
            value={book.id}
            style={{ marginRight: "16px"
             }}
          />
          <img
            src="/images/book_icon.svg"
            alt="Book Icon"
            style={{
              marginRight: "8px",
              color: "#FF6C42", // Optional, won't affect the SVG color if it doesn't use `currentColor`
              height: "16px",
              width: "auto",
              transform: "translateY(3px)"
            }}
          />
          {book.title}
        </CustomMenuItem>
      ))}
    </Menu>
  );
}

export default BooksMenu;
