import React from "react";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Card,
  Typography,
  Button,
  Space,
  Table,
  theme,
} from "antd";
import {
  HomeOutlined,
  DollarOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { CreditCard } from "../../components";

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

const InfoItem = ({ label, value }) => (
  <div style={{ marginBottom: "10px" }}>
    <Text strong style={{ marginRight: "5px" }}>
      {label}:
    </Text>
    <Text>{value}</Text>
  </div>
);

const BalanceCard = ({ icon, title, subtitle, amount, style }) => {
  const { token } = useToken();
  const isPositive = amount >= 0;

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        border: token.border,
        borderColor: token.borderColor,
        paddingLeft: "32px",
        paddingTop: "16px",
        paddingBottom: "16px",
        ...style,
      }}
      bodyStyle={{ padding: 0 }}
    >
      {icon}
      <Title level={4}>{title}</Title>
      <Text type="secondary">{subtitle}</Text>
      <Title level={3} style={{ color: isPositive ? "green" : "red" }}>
        {isPositive ? "+" : "-"}${Math.abs(amount).toLocaleString()}
      </Title>
    </Card>
  );
};

function Billing() {
  const { token } = useToken();

  const invoicesColumns = [
    { title: "Invoice", dataIndex: "invoice", key: "invoice" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const transactionsColumns = [
    { title: "Transaction", dataIndex: "transaction", key: "transaction" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  return (
    <Layout>
      <Breadcrumb style={{ margin: "16px 24px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <DollarOutlined />
          <span>Billing</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Layout>
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8}>
              <CreditCard
                number="4111111111111111"
                name="John Doe"
                expiry="10/24"
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <BalanceCard
                icon={<DollarOutlined />}
                title="Salary"
                subtitle="Incoming"
                amount={40000}
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <BalanceCard
                icon={<DollarOutlined />}
                title="Paypal"
                subtitle="Payments"
                amount={20000}
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
          </Row>

          <Card
            style={{
              marginTop: 16,
              border: token.border,
              borderColor: token.borderColor,
              backgroundColor: token.colorWhite,
              height: "300px",
            }}
          >
            <Row justify="space-between" align="middle">
              <Title level={4}>Payment Methods</Title>
              <Button icon={<PlusOutlined />}>Add Card</Button>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <CreditCard
                  number="4111111111111111"
                  name="John Doe"
                  expiry="10/24"
                  style={{ width: "100%", height: "100%" }}
                />
                <Button icon={<EditOutlined />} style={{ marginTop: 8 }}>
                  Edit
                </Button>
              </Col>
              <Col span={12}>
                <CreditCard
                  number="5555555555554444"
                  name="John Doe"
                  expiry="11/25"
                  style={{ width: "100%", height: "100%" }}
                />
                <Button icon={<EditOutlined />} style={{ marginTop: 8 }}>
                  Edit
                </Button>
              </Col>
            </Row>
          </Card>

          <Card
            style={{
              marginTop: 16,
              marginBottom: 16,
              border: token.border,
              borderColor: token.borderColor,
              backgroundColor: token.colorWhite,
            }}
          >
            <Row justify="space-between" align="middle">
              <Title level={4}>Billing Information</Title>
              <Space>
                <Button icon={<EditOutlined />}>Edit</Button>
                <Button icon={<DeleteOutlined />}>Delete</Button>
              </Space>
            </Row>
            <InfoItem label="Full Name" value="John Doe" />
            <InfoItem label="Mobile" value="+925588855555" />
            <InfoItem label="Email" value="xyz@someone.com" />
            <InfoItem label="Location" value="India" />
          </Card>
        </Content>

        <Sider
          width="30%"
          style={{ background: token.colorBgLayout, padding: "0 24px" }}
        >
          <Card title="Invoices" style={{ marginBottom: 16 }}>
            <Table columns={invoicesColumns} dataSource={[]} size="small" />
          </Card>
          <Card title="Transactions">
            <Table columns={transactionsColumns} dataSource={[]} size="small" />
          </Card>
        </Sider>
      </Layout>
    </Layout>
  );
}

export default Billing;
