// This is the file where the menu items of the drawer are defined.
import { Flex, theme } from "antd";
import {
  DashboardDrawerIcon,
  ReportsDrawerIcon,
  AskYourBookDrawerIcon,
  TakeATestDrawerIcon,
  LangPracticeDrawerIcon,
  SettingsDrawerIcon,
  NotificationsDrawerIcon,
} from "../../assets";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const { useToken } = theme;

// A component that renders the label of a menu item with a badge count if it exists
const MenuItemLabel = ({ label, badgeCount }) => {
  const { token } = useToken();

  return (
    <Flex
      justify="space-between"
      align="center"
      style={{ width: "100%", marginLeft: "16px" }}
    >
      {label}
      {badgeCount && (
        <div
          style={{
            backgroundColor: token.colorPrimary,
            color: token.colorWhite,
            borderRadius: 8,
            fontSize: "12px",

            lineHeight: "24px",
            padding: "0 8px",
            minWidth: "20px", // Minimum width for single digits

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {badgeCount}
        </div>
      )}
    </Flex>
  );
};

// MenuItemContent component to handle hover and selected states
const MenuItemContent = ({ label, Icon, badgeCount, itemKey, collapsed }) => {
  const { token } = useToken();
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname === itemKey;

  const getColor = () => {
    if (isSelected) return token.colorPrimary;
    if (isHovered) return token.colorLightGrey;
    return token.colorGrey;
  };

  return (
    <Flex
      align="center"
      style={{
        color: getColor(),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {Icon && <Icon className="menu-icon" color={getColor()} />}
      {!collapsed && <MenuItemLabel label={label} badgeCount={badgeCount} />}
    </Flex>
  );
};

const createMenuItem = (
  label,
  key,
  Icon,
  children = null,
  badgeCount = null
) => ({
  key,
  children,
  title: label,
  label: (
    <MenuItemContent
      label={label}
      Icon={Icon}
      badgeCount={badgeCount}
      itemKey={key}
    />
  ),
});

export const navigationItems = [
  createMenuItem("Dashboard", "/", DashboardDrawerIcon, null, 10),
];

export const mainItems = [
  createMenuItem("Reports", "/reports", ReportsDrawerIcon),
  createMenuItem("Ask your book", "/book", AskYourBookDrawerIcon),
  createMenuItem("Take a test", "/test", TakeATestDrawerIcon),
  createMenuItem("Language Practice", "/lang-practice", LangPracticeDrawerIcon),
];

export const settingsItems = [
  createMenuItem("Settings", "/settings", SettingsDrawerIcon),
  createMenuItem(
    "Notifications",
    "/notifications",
    NotificationsDrawerIcon,
    null,
    5
  ),
];
