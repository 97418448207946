import React from "react";
import { Drawer, Button, Space, theme } from "antd";

const MobileDrawer = ({ visible, onClose }) => {
  const { token } = theme.useToken();

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={visible}
      width={300}
      styles={{
        body: {
          padding: 0,
        },
        wrapper: {
          backgroundColor: token.colorWhite,
        },
        content: {
          backgroundColor: token.colorWhite,
        },
      }}
    >
      <div style={{ padding: "24px" }}>
        <Space direction="vertical" size={16} style={{ width: "100%" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: token.colorBgLayout,
              color: token.colorBlack,
              borderRadius: "12px",
              padding: "12px 16px",
              fontWeight: "400",
              fontSize: "12px",
              width: "100%",
              height: "48px",
              boxShadow: "none",
            }}
          >
            Login
          </Button>
          <Button
            type="primary"
            style={{
              borderRadius: "12px",
              backgroundColor: token.colorBlue,
              color: token.colorWhite,
              padding: "12px 16px",
              fontWeight: "400",
              fontSize: "12px",
              width: "100%",
              height: "48px",
              boxShadow: "none",
            }}
          >
            Signup
          </Button>
        </Space>
      </div>
    </Drawer>
  );
};

export default MobileDrawer; 