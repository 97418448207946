import React from "react";
import { Flex, Typography, theme } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

function StatText({
  value,
  description,
  isIncrease = true,
  backgroundColor,
  color,
}) {
  const { token } = useToken();

  const defaultColor = isIncrease ? token.colorSuccess : token.colorError;
  const finalColor = color || defaultColor;
  const finalBgColor = backgroundColor || `${finalColor}20`;

  return (
    <Flex justify="center" align="center">
      <Flex
        justify="center"
        align="center"
        style={{
          backgroundColor: finalBgColor,
          borderRadius: "8px",
          padding: "4px 8px",
          marginRight: "8px",
        }}
      >
        {isIncrease ? (
          <ArrowUpOutlined style={{ color: finalColor }} />
        ) : (
          <ArrowDownOutlined style={{ color: finalColor }} />
        )}
      </Flex>
      <Text strong style={{ color: finalColor, marginRight: "8px" }}>
        {isIncrease ? "+" : "-"}
        {value}
      </Text>
      <Text type="secondary" style={{ fontSize: "14px" }}>
        {description}
      </Text>
    </Flex>
  );
}

export default StatText;
