import React from "react";
import { Message, RecommendationsCard } from "../../components";

function MessageList({
  messages,
  showRecommendations,
  onCloseRecommendations,
  onSendRecommendation,
  onReferenceClicked,
}) {
  return (
    <div
      style={{
        width: "100%",
        padding: "12px",
        overflowY: "auto",
      }}
    >
      {messages.map((message, index) => (
        <Message
          key={index}
          content={message.content}
          type={message.type}
          index={index}
          onReferenceClicked={onReferenceClicked}
        />
      ))}
      {showRecommendations && (
        <RecommendationsCard
          onClose={onCloseRecommendations}
          onSend={onSendRecommendation}
        />
      )}
    </div>
  );
}

export default MessageList;
