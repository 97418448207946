import React from "react";
import { Layout, Typography, Space, Button, Flex, theme, Tooltip } from "antd";
import { CheckCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { QuizOptionCard } from "../../components";

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

function QuizResultDisplay() {
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if state exists and has required data
  if (!location.state || !location.state.quizData) {
    return <Navigate to="/test" replace />;
  }

  const { quizData, userAnswers, timeElapsed, score, skippedQuestions } =
    location.state;

  // Function to determine dynamic color based on score
  const getScoreColor = (score) => {
    if (score >= 80) return token.colorSuccess; // high score, green
    if (score >= 50) return token.colorWarning; // moderate score, yellow
    return token.colorError; // low score, red
  };

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        padding: "24px",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content style={{ width: "60%" }}>
        <Space
          direction="vertical"
          size="large"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          {/* Result Header */}
          <Flex vertical align="center" style={{ width: "100%" }}>
            <CheckCircleFilled
              style={{ fontSize: "64px", color: getScoreColor(score) }}
            />
            <Title level={2}>Quiz Completed!</Title>

            <Space direction="vertical" align="center">
              <Title level={3}>Score: {score}%</Title>
              <Title level={5}>
                Times question skipped: {skippedQuestions.length}
              </Title>
              <Text>Time taken: {timeElapsed}</Text>
            </Space>
          </Flex>

          {/* Questions Review */}
          <Space
            direction="vertical"
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <Title level={4}>Questions Review</Title>
            {quizData.map((quiz, idx) => (
              <Flex
                key={idx}
                vertical
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
              >
                <Text
                  strong
                  style={{
                    marginBottom: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {idx + 1}. {quiz.question}
                  {/* Check if the question was skipped and display the emoji and info icon */}
                  {skippedQuestions.includes(idx) && (
                    <Tooltip title="This is a question that you skipped in the quiz.">
                      <span style={{ marginLeft: 8 }}>
                        ⏪ <InfoCircleOutlined style={{ marginLeft: 4 }} />
                      </span>
                    </Tooltip>
                  )}
                </Text>

                {quiz.options.map((option, optIdx) => (
                  <QuizOptionCard
                    key={optIdx}
                    optionNumber={String.fromCharCode(65 + optIdx)}
                    text={option.text}
                    isCorrect={option.isCorrect}
                    highlighted={userAnswers[idx] === optIdx}
                    showCorrect={true}
                    isWrong={userAnswers[idx] === optIdx && !option.isCorrect}
                  />
                ))}
              </Flex>
            ))}
          </Space>

          {/* Action Buttons */}
          <Flex
            gap={16}
            style={{ marginTop: "32px" }}
            align="center"
            justify="center"
          >
            <Button type="primary" onClick={() => navigate("/test")}>
              Take Another Quiz
            </Button>
            <Button onClick={() => navigate("/")}>Go Home</Button>
          </Flex>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizResultDisplay;
