import styled from "styled-components";
import { Menu } from "antd";

// Styled components for the drawer menu(a menu which displays all the items of a section for the drawer) and section label.
export const DrawerMenu = styled(Menu)`
  // Remove the border of the menu
  border: none !important;

  // Set the background color of the menu
  background-color: ${({ theme }) => theme.token.colorBgLayout};

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    &:hover {
      // On hover, set the background color of the menu item to white
      background-color: ${({ theme }) => theme.token.colorWhite} !important;
    }
  }

  .ant-menu-item-selected {
    // Set the background color of the selected menu item to white
    background-color: ${({ theme }) => theme.token.colorWhite} !important;
  }
`;

export const SectionLabel = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.token.colorGrey};
  font-size: 10px;
  padding: 0 24px;
  margin-top: 16px;
  margin-bottom: 8px;
`;
