import React, { useState } from "react";
import { Button } from "antd";
import { ChaptersMenu } from "../../book";
import BooksMenu from "./books-menu";

function BookInformation({ bookData, onBookSelect, selectedBookId, collapsed }) {
  const [activeTab, setActiveTab] = useState("Books"); // Keep track of the selected tab

  if (collapsed) {
    return null; // Render nothing if the drawer is collapsed
  }

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        maxHeight: "100%", // Ensure it doesn't exceed parent bounds
        overflow: "hidden", // Hide anything that overflows the container
      }}
    >
      {/* Tabs container */}
      <div
        style={{
          backgroundColor: "#F5F7FA",
          padding: "4px", // Gap between the buttons
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Books Tab */}
        <Button
          type="text"
          style={{
            flex: 1,
            backgroundColor: activeTab === "Books" ? "#8BC34A" : "#FFFFFF",
            color: activeTab === "Books" ? "#FFFFFF" : "#000000",
            borderRadius: "8px",
            marginRight: "4px",
            height: "37px"
          }}
          onClick={() => setActiveTab("Books")}
        >
          Books
        </Button>

        {/* Chapters Tab */}
        <Button
          type="text"
          style={{
            flex: 1,
            backgroundColor: activeTab === "Chapters" ? "#8BC34A" : "#FFFFFF",
            color: activeTab === "Chapters" ? "#FFFFFF" : "#000000",
            borderRadius: "8px",
            height: "37px"
          }}
          onClick={() => setActiveTab("Chapters")}
        >
          Chapters
        </Button>
      </div>

      {/* Conditional rendering of content based on activeTab */}
      <div
        style={{
          maxHeight: "calc(100vh - 80px)", // Adjust based on your UI
          overflowY: "auto", // Enable scrolling for overflow
        }}
      >
        {activeTab === "Books" ? (
          <BooksMenu onBookSelect={onBookSelect} selectedBookId={selectedBookId} />
        ) : (
          <ChaptersMenu bookData={bookData} />
        )}
      </div>
    </div>
  );
}

export default BookInformation;
