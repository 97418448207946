const BASE_URL = `${process.env.PUBLIC_URL}`;

const ASSETS = {
  // Images
  logo: `${BASE_URL}/images/grademakers-logo.svg`,

  // Auth
  googleLogo: `${BASE_URL}/images/google-logo-auth-screens.svg`,
  csBookCard: `${BASE_URL}/images/cs-book-card-auth-screens.svg`,
  laBookCard: `${BASE_URL}/images/la-book-card-auth-screens.svg`,
  phyBookCard: `${BASE_URL}/images/phy-book-card-auth-screens.svg`,
  waveHand: `${BASE_URL}/images/wave-hand-auth-screens.png`,

  // Drawer icons
  dashboardDrawerIcon: `${BASE_URL}/images/dashboard-drawer-icon.svg`,
  reportsDrawerIcon: `${BASE_URL}/images/reports-drawer-icon.svg`,
  askYourBookDrawerIcon: `${BASE_URL}/images/ask-your-book-drawer-icon.svg`,
  takeATestDrawerIcon: `${BASE_URL}/images/take-a-test-drawer-icon.svg`,
  langPracticeDrawerIcon: `${BASE_URL}/images/lang-practice-drawer-icon.svg`,
  settingsDrawerIcon: `${BASE_URL}/images/settings-drawer-icon.svg`,
  notificationsDrawerIcon: `${BASE_URL}/images/notifications-drawer-icon.svg`,

  // Dashboard Sider
  dashboardPremiumCardBackground: `${BASE_URL}/images/dashboard-premium-card-background.svg`,
  dashboardPremiumCardHeader: `${BASE_URL}/images/dashboard-premium-card-header.svg`,

  // Animations
  welcomeWave: `${BASE_URL}/animations/wave-welcome-screen.gif`,
  dashboardPremiumCardAnimation: `${BASE_URL}/animations/dashboard-premium-card-animation.gif`,
  landingStudentAnimation: `${BASE_URL}/animations/landing-student-graphic.json`,

  // Landing Page
  // Hero Section
  shineThreeLinesGraphic: `${BASE_URL}/images/shine-three-lines.svg`,

  // We've got you covered section
  coveredArrow: `${BASE_URL}/images/covered-arrow.svg`,
  coveredCircleTextGraphic: `${BASE_URL}/images/covered-circle-text-graphic.svg`,
  coveredTextLeftGraphic: `${BASE_URL}/images/covered-text-left-graphic.svg`,

  // AI Works Section
  aiWorksSectionGraphic: `${BASE_URL}/images/ai-works-section-graphic.svg`,

  // Testimonial Section
  testimonialsHatGraphic: `${BASE_URL}/images/testimonials-hat-graphic.svg`,
};

export default ASSETS;
