import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
  height: ${props => props.height || '100%'};
  min-height: ${props => props.minHeight || 'auto'};
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    height: ${props => props.mobileHeight || props.height || '100%'};
    max-height: ${props => props.maxHeight || 'none'};
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 12px;
`;

const GraphicCard = ({ 
  imageUrl, 
  height, 
  minHeight,
  mobileHeight,
  maxHeight,
  style 
}) => {
  return (
    <ImageContainer 
      height={height} 
      minHeight={minHeight}
      mobileHeight={mobileHeight}
      maxHeight={maxHeight}
      style={style}
    >
      <StyledImage src={imageUrl} alt="Graphic" />
    </ImageContainer>
  );
};

export default GraphicCard; 