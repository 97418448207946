import React from "react";
import { Select, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { useToken } = theme;

function AppDropdown({
  options,
  defaultValue,
  width = 120,
  onChange,
  value,
  borderRadius = "24px",
}) {
  const { token } = useToken();

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      style={{
        width,
        borderRadius,
        backgroundColor: token.colorWhite,
      }}
      options={options}
      dropdownStyle={{
        borderRadius: "8px",
        backgroundColor: token.colorWhite,
      }}
      suffixIcon={<DownOutlined style={{ color: token.colorTextSecondary }} />}
    />
  );
}

export default AppDropdown;
