import React, { useState } from "react";
import { Typography, Modal, theme } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  overflow: hidden;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;
`;

const CirclesContainer = styled.div`
  position: relative;
  min-height: 50vh;
  margin: 40px 0;
  width: 100%;
`;

const CircleCard = styled.div`
  position: absolute;
  width: ${props => props.size}vw;
  height: ${props => props.size}vw;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  left: ${props => {
    // Adjust horizontal spacing for wider screens
    const screenWidth = window.innerWidth;
    const compressionFactor = screenWidth > 1600 ? 0.85 : 
                            screenWidth > 1200 ? 1 : 
                            1;
    const centerPoint = 50;
    const offsetFromCenter = (props.left - 50) * compressionFactor;
    return centerPoint + offsetFromCenter;
  }}%;
  top: ${props => {
    // Adjust vertical spacing for wider screens
    const screenWidth = window.innerWidth;
    const compressionFactor = screenWidth > 1600 ? 0.7 : 
                            screenWidth > 1200 ? 1 : 
                            1;
    const centerPoint = 25; // Adjust this value to move all circles up or down
    const offsetFromCenter = (props.top - 25) * compressionFactor;
    return centerPoint + offsetFromCenter;
  }}vh;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  z-index: ${props => props.zIndex};

  @media (max-width: 1600px) {
    width: ${props => props.size * 1.2}vw;
    height: ${props => props.size * 1.2}vw;
  }

  @media (max-width: 1200px) {
    width: ${props => props.size * 1.4}vw;
    height: ${props => props.size * 1.4}vw;
  }

  @media (max-width: 768px) {
    width: ${props => props.size * 1.8}vw;
    height: ${props => props.size * 1.8}vw;
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
    z-index: 10;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.token.colorPrimary};
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: ${props => props.theme.token.colorWhite};
  }
  
  .ant-modal-header {
    background-color: ${props => props.theme.token.colorWhite};
  }
`;

const PracticeCircle = styled(CircleCard)`
  background-color: ${props => props.theme.token.colorPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
    background-color: ${props => props.theme.token.colorPrimaryHover};
  }
`;

const RecommendedSection = () => {
  const { token } = useToken();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const recommendedData = [
    { size: 10, image: "recommend-image-1.png", videoUrl: "https://youtube.com/watch?v=dummy1", left: 15, top: 15, zIndex: 1 },
    { size: 9, image: "recommend-image-6.png", videoUrl: "https://youtube.com/watch?v=dummy2", left: 30, top: 25, zIndex: 2 },
    { size: 14, image: "recommend-image-2.png", videoUrl: "https://youtube.com/watch?v=dummy3", left: 48, top: 28, zIndex: 3 },
    { size: 7.5, image: "recommend-image-3.png", videoUrl: "https://youtube.com/watch?v=dummy4", left: 65, top: 15, zIndex: 4 },
    { size: 7.3, image: "recommend-image-7.png", videoUrl: "https://youtube.com/watch?v=dummy5", left: 77, top: 22, zIndex: 5 },
    { size: 5, image: "recommend-image-9.png", videoUrl: "https://youtube.com/watch?v=dummy6", left: 26, top: 43, zIndex: 6 },
    { size: 7.5, image: "recommend-image-5.png", videoUrl: "https://youtube.com/watch?v=dummy7", left: 35.5, top: 48, zIndex: 7 },
    { size: 10, image: "recommend-image-8.png", videoUrl: "https://youtube.com/watch?v=dummy8", left: 67, top: 39, zIndex: 8 },
    { size: 4.5, image: "recommend-image-10.png", videoUrl: "https://youtube.com/watch?v=dummy9", left: 78, top: 48, zIndex: 9 },
    { size: 14, image: "recommend-image-4.png", videoUrl: "https://youtube.com/watch?v=dummy10", left: 91.5, top: 36, zIndex: 1 },
  ];

  const handleCardClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setModalVisible(true);
  };

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 500,
            maxWidth: "70%",
            margin: "0 auto",
          }}
        >
          Teachers, parents & students recommends GradesMakers
        </Title>
      </HeaderSection>

      <CirclesContainer>
        <PracticeCircle
          size={8}
          left={15}
          top={40}
          zIndex={2}
          theme={{ token }}
          onClick={() => {/* Add navigation logic here */}}
        >
          <Text
            style={{
              color: token.colorWhite,
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center"
            }}
          >
            Practice
            <br />
            Now
          </Text>
        </PracticeCircle>

        {recommendedData.map((item, index) => (
          <CircleCard 
            key={index} 
            size={item.size}
            left={item.left}
            top={item.top}
            zIndex={item.zIndex}
            onClick={() => handleCardClick(item.videoUrl)}
          >
            <img src={`images/${item.image}`} alt={`Recommendation ${index + 1}`} />
            <PlayButton theme={{ token }}>
              <PlayCircleFilled 
                style={{ 
                  fontSize: "40px",
                  color: token.colorBlack 
                }} 
              />
            </PlayButton>
          </CircleCard>
        ))}
      </CirclesContainer>

      <StyledModal
        title="Recommendations"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
        theme={{ token }}
      >
        <iframe
          width="100%"
          height="450"
          src={selectedVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </StyledModal>
    </SectionContainer>
  );
};

export default RecommendedSection; 