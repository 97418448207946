import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

function TextBadge({
  text,
  backgroundColor,
  textColor,
  isBorder = true,
  borderColor,
}) {
  return (
    <div
      style={{
        border: isBorder ? `1px solid ${borderColor || textColor}` : "none",
        padding: "4px 12px",
        borderRadius: "16px",
        backgroundColor,
      }}
    >
      <Text style={{ color: textColor }}>{text}</Text>
    </div>
  );
}

export default TextBadge;
