import React from "react";
import { Typography, Flex } from "antd";
import styled from "styled-components";

const { Text } = Typography;

const BadgeContainer = styled.div`
  padding: 1vh 1vw;
  border-radius: 8px;
  box-shadow: 0px 3px 6.3px 0px #0000000F;
  width: 100%;
  box-sizing: border-box;
  transform: rotate(${props => props.rotation || 0}deg);
`;

const BorderDiv = styled.div`
  border-left: 3px solid ${props => props.borderColor};
  padding: 1vh 1vw;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    font-size: calc(0.8em + 0.4vw);
    line-height: 1.2;
  }

  @media (max-width: 768px) {
    padding: 0.8vh 0.8vw;
    
    & > span {
      font-size: calc(0.7em + 0.3vw);
      line-height: 1;
    }
  }
`;

const InfoBadge = ({ text, borderColor, rotation }) => {
  return (
    <BadgeContainer rotation={rotation}>
      <BorderDiv borderColor={borderColor}>
        <Text
          style={{
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "1"
          }}
        >
          {text}
        </Text>
      </BorderDiv>
    </BadgeContainer>
  );
};

export default InfoBadge;