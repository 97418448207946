import React from "react";
import { Avatar, Space, Badge, Typography, theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

function AvatarWithText({
  text,
  subtitle,
  isBadge,
  initials,
  onClick,
  image,
  avatarSize = 42,
}) {
  const { token } = useToken();

  return (
    <Space onClick={onClick} style={{ cursor: "pointer" }}>
      <Badge dot={isBadge} offset={[-4, 10]} color="green">
        <Avatar
          size={avatarSize}
          src={image}
          style={{
            backgroundColor: image ? "transparent" : token.colorAccent,
            color: token.colorTextLightSolid,
          }}
        >
          {!image && initials}
        </Avatar>
      </Badge>
      <div>
        <Text type="primary">{text}</Text>
        <Text type="secondary">{subtitle}</Text>
      </div>
    </Space>
  );
}

export default AvatarWithText;
