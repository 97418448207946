import { REGISTER_URL, LOGIN_URL, ONBOARDING_URL } from "./api-urls";

export const registerUser = async (userData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  };

  try {
    const response = await fetch(REGISTER_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const loginUser = async (credentials) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  };

  try {
    const response = await fetch(LOGIN_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateOnboarding = async (onboardingData, token) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(onboardingData),
  };

  try {
    const response = await fetch(ONBOARDING_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
