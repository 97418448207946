import React from "react";
import { Flex, Typography, theme } from "antd";
import { MessageOption } from "../../components";

const { Text } = Typography;
const { useToken } = theme;

function Message({ content, type, index, onReferenceClicked }) {
  const isUser = type === "user";
  const { token } = useToken();

  return (
    <Flex
      vertical
      align={isUser ? "flex-end" : "flex-start"}
      style={{
        margin: "8px 0",
      }}
    >
      <Flex
        style={{
          maxWidth: "60%",
          padding: "8px 12px",
          borderRadius: "16px",
          background: isUser ? "#0D52FB" : "#eaf2f7",
          textAlign: isUser ? "right" : "left",
        }}
      >
        <Text style={{ color: isUser ? "#fff" : "#000" }}>{content}</Text>
        {!isUser && (
          <Flex
            style={{
              height: "10%",
              margin: "3px",
              marginLeft: "5px",
              padding: "1px 3px",
              borderRadius: "16px",
              background: "#d3dade",
              color: token.colorPrimary,
              cursor: "pointer",
            }}
            onClick={onReferenceClicked}
          >
            <Text style={{ fontSize: "10px" }}>1</Text>
          </Flex>
        )}
      </Flex>
      {!isUser && <MessageOption content={content} index={index} />}
    </Flex>
  );
}

export default Message;
