import React from "react";
import { Layout, Typography, Space, Row, Col } from "antd";
import { ClockCircleOutlined, TagsOutlined } from "@ant-design/icons";
import { useSelectedChapters } from "../../contexts";
import { theme } from "antd";

const { useToken } = theme;
const { Content } = Layout;

function QuizInfoDisplay() {
  const { token } = useToken();
  const { selectedChapters } = useSelectedChapters();

  // Join selected chapters into a comma-separated string
  const chapterTitles = selectedChapters.join(", ");

  return (
    <Layout
      style={{
        width: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        marginBottom: "24px", // To add space below this component
      }}
    >
      <Content>
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          {/* Chapter Titles */}
          <Typography.Title level={4}>
            Chapters: {chapterTitles || "No chapters selected"}
          </Typography.Title>

          {/* Category */}
          <Row>
            <Col>
              <TagsOutlined style={{ fontSize: "16px", marginRight: "8px" }} />
              <Typography.Text strong>Category:</Typography.Text>{" "}
              <Typography.Text>General Knowledge</Typography.Text>
            </Col>
          </Row>

          {/* Estimated Duration */}
          <Row>
            <Col>
              <ClockCircleOutlined
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <Typography.Text strong>Estimated Duration:</Typography.Text>{" "}
              <Typography.Text>15 minutes</Typography.Text>
            </Col>
          </Row>

          {/* Quiz Description */}
          <Typography.Paragraph>
            This quiz will test your knowledge on various topics from the
            selected chapters. It is designed to challenge your understanding
            and improve your retention. Expect a mix of multiple-choice
            questions and short answers, with a focus on key concepts and
            critical thinking.
          </Typography.Paragraph>

          {/* Instructions */}
          <Typography.Text strong>Instructions:</Typography.Text>
          <ul>
            <li>Read each question carefully before answering.</li>
            <li>You have one attempt for each question.</li>
            <li>Use the "Next" button to move to the next question.</li>
            <li>Submit your quiz once you have answered all questions.</li>
          </ul>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizInfoDisplay;
