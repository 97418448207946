import { Flex, Typography, Divider, theme } from "antd";
import QuizSettingsIcon from "./quiz-settings-icon";
import {
  PlayCircleOutlined,
  PauseOutlined,
  ReloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

function QuizInfoStickyBar({
  questionsAnswered,
  totalQuestions,
  timeLeft,
  onPauseResume,
  onRestart,
  onStop,
  isPaused,
}) {
  const { token } = useToken();

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        padding: "12px 24px",
        backgroundColor: token.colorWhite,
        borderBottom: token.border,
        height: "64px",
      }}
    >
      <Flex align="center">
        <Flex vertical>
          <Text strong>Progress</Text>
          <Text>{`${questionsAnswered}/${totalQuestions}`}</Text>
        </Flex>
      </Flex>

      <Divider type="vertical" style={{ height: "40px" }} />

      <Flex align="center">
        <Flex vertical>
          <Text strong>Time Elapsed</Text>
          <Text>{timeLeft}</Text>
        </Flex>
      </Flex>

      <Divider type="vertical" style={{ height: "40px" }} />

      <Flex gap={8}>
        <QuizSettingsIcon
          icon={isPaused ? <PlayCircleOutlined /> : <PauseOutlined />}
          tooltipTitle={isPaused ? "Resume Quiz" : "Pause Quiz"}
          onClick={onPauseResume}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<ReloadOutlined />}
          tooltipTitle="Restart Quiz"
          onClick={onRestart}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<CloseOutlined />}
          tooltipTitle="Stop Quiz"
          onClick={onStop}
          border={token.border}
        />
      </Flex>
    </Flex>
  );
}

export default QuizInfoStickyBar;
